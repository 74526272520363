import React, {useEffect, useState} from "react";
import InfiniteScroll from "react-infinite-scroller";
import {useDispatch, useSelector} from "react-redux";
import Async from 'react-select/async';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Breadcrumbs from "../../../components/breadcrumbs";
import Button from "../../../components/button";
import Card from "../../../components/card";
import CardItem from "../../../components/card-item";
import CheckBox from "../../../components/check-box";
import Input from "../../../components/input";
import ItemChat from "../../../components/item-chat";
import ItemList1 from "../../../components/item-list-1";
import Search from "../../../components/search";
import SelectInput from "../../../components/select";
import TextArea from "../../../components/text-area";
import {get_status, URLAPI} from "../../../config";
import {
    clearVisit,
    createVisit,
    CREATE_VISIT,
    get_report,
    getAllVisits,
    searchVisitant,
    updateVisit,
    UPDATE_VISIT,
    GET_REPORT,
    getEmployeeZone
} from '../../../redux/visits';
import VisitantModal from './visitant-modal';
import Select from "react-select";
import Modal from "../../../components/modal";
import LoadingSpinner from "../../../components/loading-spinner";


const VisitasAdmin = (props) => {
    const dispatch = useDispatch();

    /* Usando el gancho useSelector para obtener el estado del módulo de visitas. */
    const _get_all_visits = useSelector(
        (state) => state.visits_module._get_all_visits
    );

    const _create_visit = useSelector
    ((state) => state.visits_module._create_visit);

    const _update_visit = useSelector
    ((state) => state.visits_module._update_visit);


    const _get_report = useSelector((store) => store.visits_module._get_report);
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    useEffect(() => {
        dispatch(getAllVisits(token, userEstablishmentsList.establecimientos?.map((par) => par.id), 1));
        // eslint-disable-next-line
    }, [token, dispatch]);

    const userEstablishmentsList = useSelector(
        (state) => state.users_module._session_data.data
    );

    const [visitantmodal, setVisitantModal] = useState(
        {
            ruc: '',
            nombres: '',
            apellidos: '',
            foto: ''
        }
    )
    /* const _get_all_establishments = useSelector(
        (state) => state.establishment_module._get_all_establishments) */

    const [visitantsSelected, setVisitantSelected] = useState({
        visitantes: [],
        empleados: [],
        proveedores: [],
    });

    const [showDetail, setShowDetail] = useState(false);
    const [showSibarLeft, setShowSibarLeft] = useState(false);
    const [showSibarRight, setShowSibarRight] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);
    // const [report, setReport] = useState(false);

    const [visitantSelectOptions, setVisitantSelectOptions] = useState([])
    const [establishment, setEstablishment] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    /* Creating an array of objects. */
    const filter_options = [
        {name: "HOY", id: 1},
        {name: "AYER", id: 2},
        {name: "ÚLTIMOS 7 DÍAS", id: 3},
        {name: "ÚLTIMOS 30 DÍAS", id: 4},
        {name: 'Rango de fechas', id: 5}
    ];


    const [range_date, setRangeDate] = useState({
        visible: false,
        date_start: '',
        date_end: ''
    })

    const [visits, setVisits] = useState([]);
    const [count, setCount] = useState(0);
    const [HasMore, setHasMore] = useState(true);
    const [n_page, setNPage] = useState(0);

    const dataSelectedInit = {
        id: 0,
        fecha_creacion: "",
        titulo: "",
        descripcion: "",
        observacion: "",
        usuario_crea: "",
        visita_programada: true,
        fecha_visita: "",
        fecha_solicitud_aprobacion: "",
        fecha_respuesta_aprobacion: "",
        establecimiento: "",
        estado: "",
        empleados: [],
        visitantes: [],
        proveedores: []
    }
    const [DataSelected, setDataSelected] = useState(dataSelectedInit);

    // eslint-disable-next-line
    const initForm = {
        titulo: "",
        descripcion: "",
        observacion: "",
        usuario_crea: "",
        id_establecimiento: 0,
        visita_programada: true,
        fecha: "",
        visitantes: [],
        establecimiento: {id: 0, name: "Seleccione una opcion"}
    }
    // eslint-disable-next-line
    const [form, setForm] = useState(initForm)

    const [permisos, setPermisos] = useState([]);

    // eslint-disable-next-line
    const [filtros, setFiltros] = useState({
        creadas: false,
        aprobadas: false,
        caducadas: false,
        pendiente_zonal: false,
        espera: false,
        rechazadas: false,
    });


    // useEffect(() => {
    //     setReport(_get_report.status)
    // }, [_get_report])


    useEffect(() => {
        let array = []
        if (userEstablishmentsList) {
            if (userEstablishmentsList.establecimientos) {
                array = userEstablishmentsList.establecimientos.map(element => {
                    return {
                        value: element.id,
                        label: element.nombre
                    }
                })
            }
        }
        setEstablishment(array)
    }, [userEstablishmentsList])

    useEffect(() => {
        if (_update_visit.status === 200) {
            dispatch(clearVisit(UPDATE_VISIT));
            toast.success(_update_visit.message);
            dispatch(getAllVisits(token, userEstablishmentsList.establecimientos?.map((par) => par.id), 1));
            setForm(initForm);
            setShowOverlay(false);
            setShowSibarRight(false);
            setIsLoading(false);
        } else if (_update_visit.status !== 0) {
            toast.error(_update_visit.message);
            dispatch(clearVisit(UPDATE_VISIT));
            setForm(initForm);
            setShowOverlay(false);
            setShowSibarRight(false);
            setIsLoading(false);
        }
    }, [_update_visit, dispatch, token, initForm, userEstablishmentsList])

    useEffect(() => {
        if (_create_visit.status === 201) {
            toast.success("Visita creada correctamente", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setForm(initForm);
            setShowOverlay(false);
            dispatch(clearVisit(CREATE_VISIT));
            dispatch(getAllVisits(token, userEstablishmentsList.establecimientos?.map((par) => par.id), 1));
            setShowSibarRight(false);
            setModalZonal(false);
            setIsLoading(false);
        } else if (_create_visit.status !== 0) {
            toast.error(_create_visit.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setForm(initForm);
            setShowOverlay(false);
            setShowSibarRight(false);
            dispatch(clearVisit(CREATE_VISIT));
            setIsLoading(false);
        }
    }, [_create_visit, dispatch, initForm, token, userEstablishmentsList])

    useEffect(() => {
        if (props) {
            //converto to array
            const array = Object.values(props);
            setPermisos(array);
        }
        if (_get_all_visits && _get_all_visits.data && _get_all_visits.data.datos) {
            //ordenar por fecha de creacion _get_all_visits.data.datos
            // const ordenado = _get_all_visits.data.datos.sort((a, b) => {
            //     // console.log(a, b)
            //     console.log(new Date(b.fecha_visita) - new Date(a.fecha_visita))
            //     if (new Date(b.fecha_visita) > new Date(a.fecha_visita)) return 1
            //     if (new Date(b.fecha_visita) < new Date(a.fecha_visita)) return -1
            //     return 0
            //     // return new Date(b.fecha_visita) - new Date(a.fecha_visita);
            // });
            // setVisits(ordenado);
            setVisits(_get_all_visits.data.datos);
            setNPage(_get_all_visits.data.n_page);
        }

    }, [_get_all_visits, count, props]);

    const loadFunc = () => {
        if (_get_all_visits.data.datos) {
            if (_get_all_visits.data.datos.length > count) {
                const ordenado = _get_all_visits.data.datos.sort((a, b) => {
                    return new Date(b.fecha_visita + ' 00:00:00') - new Date(a.fecha_visita + ' 00:00:00');
                });
                setVisits([...visits, ...ordenado.slice(count, count + 10)]);
                setCount(count + 10);
            }
            setHasMore(false);
        }
    };

    const handleClickAprove = (type) => {
        setIsLoading(true);
        let data = {estado: type}
        setDataSelected({...DataSelected, estado: type})
        dispatch(updateVisit(token, DataSelected.id, data));
    }

    const handleAprove = (e) => {
        e.preventDefault();
    }

    const handleEdit = (item) => {
        let visitantesF = [];

        let visitantes = [];
        let empleados = [];
        let proveedores = [];

        if (item.visitantes) {
            item.visitantes.forEach(element => {
                visitantesF.push({
                    tipo: "VISITANTE",
                    ruc: element.ruc
                })
            })
            visitantes = item.visitantes;

        }

        if (item.empleados) {
            item.empleados.forEach(element => {
                visitantesF.push({
                    tipo: "EMPLEADO",
                    ruc: element.ruc
                })
            })
            empleados = item.empleados;

        }

        if (item.proveedores) {
            item.proveedores.forEach(element => {
                visitantesF.push({
                    tipo: "PROVEEDOR",
                    ruc: element.ruc
                })
            })
            proveedores = item.proveedores;

        }

        // eslint-disable-next-line
        establishment.filter(element => {
            if (element.label === item.establecimiento) {
                setForm({
                    id: item.id,
                    id_establecimiento: element.id,
                    establecimiento: element.label,
                    titulo: item.titulo,
                    descripcion: item.descripcion,
                    observacion: item.observacion,
                    usuario_crea: item.usuario_crea,
                    visita_programada: item.visita_programada,
                    fecha_visita: new Date(item.fecha_visita + ' 00:00:00').toISOString().substring(0, 10),
                    visitantes: visitantesF,
                    fecha: new Date(item.fecha_visita + ' 00:00:00').toISOString().substring(0, 10),
                })
                setEstablishmentSelect({label: element.label, value: element.value})
            }
        })

        setVisitantSelected({
            visitantes: visitantes,
            empleados: empleados,
            proveedores: proveedores
        });
    }

    const handleDelete = (e) => {
        e.preventDefault();
        setIsLoading(true);
        setDataSelected({...DataSelected, estado: "ANULADO"});
        let data = {estado: "ANULADO"}
        dispatch(updateVisit(token, DataSelected.id, data));
    }

    const filterToday = (e) => {
        let visitas = [];
        let today = new Date();
        visitas = visits;

        let actual = []
        if (filtros.aprobadas || filtros.caducadas || filtros.creadas || filtros.espera || filtros.rechazadas) {
            if (filtros.aprobadas) {
                actual = actual.concat(_get_all_visits.data.datos.filter(x => x.estado === "APROBADO"));
            }
            if (filtros.caducadas) {
                actual = actual.concat(_get_all_visits.data.datos.filter(x => x.estado === "CADUCADO"));
            }
            if (filtros.creadas) {
                actual = actual.concat(_get_all_visits.data.datos.filter(x => x.estado === "CREADO"));
            }
            if (filtros.espera) {
                actual = actual.concat(_get_all_visits.data.datos.filter(x => x.estado === "EN ESPERA"));
            }
            if (filtros.pendiente_zonal) {
                actual = actual.concat(_get_all_visits.data.datos.filter(x => x.estado === "PENDIENTE VISITA ZONAL"));
            }
            if (filtros.rechazadas) {
                actual = actual.concat(_get_all_visits.data.datos.filter(x => x.estado === "ANULADO"));
            }
        } else {
            actual = _get_all_visits.data.datos;
        }

        if (e.target.value === "0") {
            setVisits(actual)
        }

        //HOY
        if (e.target.value === "1") {
            // eslint-disable-next-line
            visitas = actual.filter((visita) => {
                if (visita.fecha_visita) {
                    let fecha = new Date(visita.fecha_visita + ' 00:00:00');
                    if (fecha.getDate() === today.getDate() && fecha.getMonth() === today.getMonth() && fecha.getFullYear() === today.getFullYear()) {
                        return visita;
                    }
                }
            })
            setVisits(visitas);

            return;
        }

        //AYER
        if (e.target.value === "2") {
            let today = new Date();
            today.setDate(today.getDate() - 1)
            // eslint-disable-next-line
            visitas = actual.filter((visita) => {
                if (visita.fecha_visita) {
                    let fecha = new Date(visita.fecha_visita + ' 00:00:00');
                    if (fecha.getDate() === today.getDate() && fecha.getMonth() === today.getMonth() && fecha.getFullYear() === today.getFullYear()) {
                        return visita;
                    }
                }
            })
            setVisits(visitas);

            return;
        }

        //ÚLTIMOS 7 DÍAS
        if (e.target.value === "3") {
            let today = new Date();

            today.setDate(today.getDate() - 7)
            // eslint-disable-next-line
            visitas = actual.filter((visita) => {
                if (visita.fecha_visita) {
                    let fecha = new Date(visita.fecha_visita + ' 00:00:00');
                    if (fecha.getTime() <= new Date().getTime() && fecha.getTime() >= today.getTime()) {

                        return visita;
                    }
                }
            })
            setVisits(visitas);

            return;
        }

        //ÚLTIMOS 30 DÍAS
        if (e.target.value === "4") {
            let today = new Date();
            today.setDate(today.getDate() - 30)
            // eslint-disable-next-line
            visitas = actual.filter((visita) => {
                if (visita.fecha_visita) {
                    let fecha = new Date(visita.fecha_visita + ' 00:00:00');
                    if (fecha.getTime() >= today.getTime() && fecha.getTime() <= new Date().getTime()) {
                        return visita;
                    }
                }
            })
            setVisits(visitas);

            return;
        }


        if (e.target.value === '5') {
            setRangeDate({
                date_end: '',
                date_start: '',
                visible: true
            })
        }

    }

    const onItemClickListener = (item) => {
        setDataSelected(item);
        setShowDetail(true);
    }

    const calculateVisitants = (item) => {
        let count = 0;
        if (item.empleados != null) {
            count += item.empleados.length;
        }
        if (item.visitantes != null) {
            count += item.visitantes.length;
        }
        if (item.proveedores != null) {
            count += item.proveedores.length;
        }
        return count;
    }

    const promiseOptions = (inputValue) => {

        if (inputValue.length === 0) {
            return Promise.resolve({options: []});
        }
        //evaluar si es un numero
        let param = "?"
        if (inputValue.match(/^[0-9]+$/)) {
            param += "ruc=" + inputValue
        }

        //evaluar si son letras
        if (inputValue.match(/^[a-zA-Z\s]+$/)) {
            param += "nombres=" + inputValue
        }

        param += "&&visita_no_programada=false"

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                searchVisitant(token, param).then((res) => {
                    let datos = []

                    res.data.forEach(element => {
                        datos.push({
                            value: element.id,
                            label: element.nombres,
                            type: element.tipo,
                            item: element
                        })
                    });
                    resolve(datos);

                }).catch((err) => {
                    reject(err);
                })
            }, 1000);
        })
    }

    const handleChange = (e) => {
        if (e.target.name === "id_establecimiento") {
            setForm({
                ...form,
                [e.target.name]: parseInt(e.target.value),
            });
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            });
        }
    }

    const [establishment_select, setEstablishmentSelect] = useState([]);

    const handleChangeEstablishment = (e) => {
        setForm({
            ...form,
            // eslint-disable-next-line
            ['id_establecimiento']: parseInt(e.value),
            // eslint-disable-next-line
            ['establecimiento']: parseInt(e.label),
        });
        setEstablishmentSelect(e);
    }

    const handleFilterState = (e, type) => {
        if (type === "CREADO") {
            filtros.creadas = e.target.checked
        }
        if (type === "APROBADO") {
            filtros.aprobadas = e.target.checked
        }

        if (type === "CADUCADO") {
            filtros.caducadas = e.target.checked
        }
        if (type === "EN ESPERA") {
            filtros.espera = e.target.checked
        }
        if (type === "PENDIENTE VISITA ZONAL") {
            filtros.pendiente_zonal = e.target.checked
        }
        if (type === "ANULADO") {
            filtros.rechazadas = e.target.checked
        }

        let actual = []

        if (filtros.aprobadas && type !== "APROBADO") {
            actual = actual.concat(_get_all_visits.data.datos.filter(x => x.estado === "APROBADO"));
        }
        if (filtros.caducadas && type !== "CADUCADO") {
            actual = actual.concat(_get_all_visits.data.datos.filter(x => x.estado === "CADUCADO"));
        }
        if (filtros.creadas && type !== "CREADO") {
            actual = actual.concat(_get_all_visits.data.datos.filter(x => x.estado === "CREADO"));
        }
        if (filtros.espera && type !== "EN ESPERA") {
            actual = actual.concat(_get_all_visits.data.datos.filter(x => x.estado === "EN ESPERA"));
        }
        if (filtros.pendiente_zonal && type !== "PENDIENTE VISITA ZONAL") {
            actual = actual.concat(_get_all_visits.data.datos.filter(x => x.estado === "PENDIENTE VISITA ZONAL"));
        }
        if (filtros.rechazadas && type !== "ANULADO") {
            actual = actual.concat(_get_all_visits.data.datos.filter(x => x.estado === "ANULADO"));
        }

        if (e.target.checked) {
            actual.concat();
            let select = _get_all_visits.data.datos.filter(x => x.estado === type);
            actual = actual.concat(select);
            // const ordenado = actual.sort((a, b) => {
            //     return new Date(b.fecha_visita) - new Date(a.fecha_visita);
            // });
            setVisits(actual)
        } else {
            if (!filtros.aprobadas && !filtros.caducadas && !filtros.creadas && !filtros.espera && !filtros.pendiente_zonal && !filtros.rechazadas) {
                actual = _get_all_visits.data.datos;
            } else {
                actual.filter(x => x.estado === type).pop()
            }
            // const ordenado = actual.sort((a, b) => {
            //     return new Date(b.fecha_visita) - new Date(a.fecha_visita);
            // });
            setVisits(actual)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);

        form.visitantes = [];
        visitantsSelected.empleados.forEach(element => {
            form.visitantes.push({
                tipo: "EMPLEADO",
                ruc: element.ruc
            });
        })

        visitantsSelected.visitantes.forEach(element => {
            form.visitantes.push({
                tipo: "VISITANTE",
                ruc: element.ruc
            });

        })

        visitantsSelected.proveedores.forEach(element => {
            form.visitantes.push({
                tipo: "PROVEEDOR",
                ruc: element.ruc
            });
        })

        if (form.titulo.length === 0) {
            toast.error("Debe ingresar un titulo");
            setIsLoading(false);
            return;
        }
        if (form.descripcion.length === 0) {
            toast.error("Debe ingresar una descripcion");
            setIsLoading(false);
            return;
        }

        if (form.visitantes.length === 0) {
            toast.error("Debe ingresar visitantes");
            setIsLoading(false);
            return;
        }
        if (form.id !== undefined) {
            dispatch(updateVisit(token, form.id, form));
        } else {
            dispatch(createVisit(token, form));
        }

        e.target.reset();
        //dispatch action
        setVisitantSelectOptions([]);
    }

    const handleChangeSelect = (e) => {
        setVisitantSelectOptions(e)
        let empleados = []
        let visitantes = []
        let proveedores = []
        e.forEach(element => {
            if (element.type === "proveedor") {
                proveedores.push(element.item)
            }

            if (element.type === "empleado") {
                empleados.push(element.item)
            }

            if (element.type === "visitante") {
                visitantes.push(element.item)
            }
        });

        setVisitantSelected({
            visitantes: visitantes,
            empleados: empleados,
            proveedores: proveedores
        });
    }


    const handleSearch = (e) => {
        setIsLoading(true);
        if (e.target.value === "") {
            setVisits(_get_all_visits.data.datos);
            return;
        }
        setVisits([]);
        let nombre = e.target.value;
        nombre = nombre.trim();
        let re = new RegExp(nombre, "i");
        // eslint-disable-next-line
        let filtro = _get_all_visits.data.datos.filter(element => {
            if (element.establecimiento.match(re) != null) {
                return element;
            }
        })
        setVisits(filtro);
        setIsLoading(false);
    }

    const handleFilterRangeDate = (e) => {
        if (e.target.name === 'date_start') {
            range_date.date_start = e.target.value
        }
        if (e.target.name === 'date_end') {
            range_date.date_end = e.target.value
        }

        let actual = []
        if (filtros.aprobadas || filtros.caducadas || filtros.creadas || filtros.espera || filtros.pendiente_zonal || filtros.rechazadas) {
            if (filtros.aprobadas) {
                actual = actual.concat(_get_all_visits.data.datos.filter(x => x.estado === "APROBADO"));
            }
            if (filtros.caducadas) {
                actual = actual.concat(_get_all_visits.data.datos.filter(x => x.estado === "CADUCADO"));
            }
            if (filtros.creadas) {
                actual = actual.concat(_get_all_visits.data.datos.filter(x => x.estado === "CREADO"));
            }
            if (filtros.espera) {
                actual = actual.concat(_get_all_visits.data.datos.filter(x => x.estado === "EN ESPERA"));
            }
            if (filtros.pendiente_zonal) {
                actual = actual.concat(_get_all_visits.data.datos.filter(x => x.estado === "PENDIENTE VISITA ZONAL"));
            }
            if (filtros.rechazadas) {
                actual = actual.concat(_get_all_visits.data.datos.filter(x => x.estado === "ANULADO"));
            }
        } else {
            actual = _get_all_visits.data.datos;
        }


        if (range_date.date_start !== '' && range_date.date_end !== '') {
            let date_start = new Date(range_date.date_start + ' 00:00:00');
            let date_end = new Date(range_date.date_end + ' 00:00:00');
            // eslint-disable-next-line
            let final = actual.filter(x => {
                    if (x.fecha_visita) {
                        let fecha = new Date(x.fecha_visita + ' 00:00:00');
                        if (fecha.getTime() >= date_start.getTime() && fecha.getTime() <= date_end.getTime()) {
                            return x;
                        }
                    }
                }
            )
            setVisits(final)
        }
    }

    const [page, setPage] = useState(1);

    function more_data() {
        let count = page + 1;
        setPage(count)
        dispatch(getAllVisits(token, userEstablishmentsList.establecimientos?.map((par) => par.id), count));
    }

    function less_data() {
        let count = page - 1;
        setPage(count)
        dispatch(getAllVisits(token, userEstablishmentsList.establecimientos?.map((par) => par.id), count));
    }

    const [fecha_inicial, setFechaInicial] = useState('');
    const [fecha_final, setFechaFinal] = useState('');
    const [empleado, setEmpleado] = useState('');
    const [employee_zonal, setEmployeeZonal] = useState('');
    const [employee_zonal_ruc, setEmployeeZonalRuc] = useState('');
    const [establecimiento_zonal, setEstablecimientoZonal] = useState('');
    const [establishment_zone_select, setEstablishmentZoneSelect] = useState([]);
    const [id_establecimiento_zonal, setIdEstablishmentZonal] = useState(0);
    const [modal, setModal] = useState(false);
    const [modalZonal, setModalZonal] = useState(false);

    useEffect(() => {
        if (_get_report.status === 200) {
            toast.success(_get_report.data.mensaje);
            dispatch(clearVisit(GET_REPORT));
            setModal(false);
            setIsLoading(false);
        } else if (_get_report.status !== 0) {
            toast.error(_get_report.message);
            dispatch(clearVisit(GET_REPORT));
            setModal(false);
            setIsLoading(false);
        }
    }, [_get_report, dispatch])

    const handleGenerateReport = (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (fecha_inicial === "") {
            toast.error("Debe seleccionar la fecha inicial");
            setIsLoading(false);
            return;
        }
        if (fecha_final === "") {
            toast.error("Debe seleccionar la fecha final");
            setIsLoading(false);
            return;
        }

        setModal(false);
        dispatch(get_report({
            'fecha_inicio': fecha_inicial,
            'fecha_fin': fecha_final,
            'colaborador': empleado,
        }, token));
    }

    async function get_employee_zone() {
        const response = await getEmployeeZone(token);
        if (response.status === 200) {
            setEmployeeZonal(response.data.datos.name);
            setEmployeeZonalRuc(response.data.datos.ruc);
            setEstablecimientoZonal(response.data.datos.establecimientos);
        }
    }

    const handleChangeEstablishmentZone = (e) => {
        setEstablishmentZoneSelect(e);
        setIdEstablishmentZonal(parseInt(e.value));
    }

    const createZonalVisit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (id_establecimiento_zonal === 0) {
            toast.error("Debe seleccionar el establecimiento");
            setIsLoading(false);
            return;
        }

        var day = new Date().getDate();
        var month = new Date().getMonth() + 1;
        var year = new Date().getFullYear();
        if (day < 10) {
            day = '0' + day;
        }
        if (month < 10) {
            month = '0' + month;
        }
        var date = year + '-' + month + '-' + day;

        let values = {
            titulo: 'Visita de ' + employee_zonal,
            descripcion: 'Visita de ' + employee_zonal + ' al establecimiento ' + establishment_zone_select.label,
            observacion: 'Visita creada por ' + employee_zonal,
            id_establecimiento: id_establecimiento_zonal,
            visita_programada: true,
            fecha: date,
            visitantes: [{
                'tipo': 'EMPLEADO',
                'ruc': employee_zonal_ruc
            }]

        }
        dispatch(createVisit(token, values));
    }

    return (
        <div className="app-content content">
            <ToastContainer/>
            {isLoading && <LoadingSpinner/>}
            <div className="content-wrapper">

                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 ">
                        <Breadcrumbs
                            title="Visitas"
                            items={[{label: "Listado Visitas", link: "/visitas"}]}
                            onclick
                        />
                    </div>
                </div>
                <div className="content-body">

                    <div className="content-area-wrapper" style={{margin: 0}}>


                        <div className={`sidebar-left ${showSibarLeft ? 'show' : ''}`}>
                            <div className="sidebar">

                                <div className="sidebar-content email-app-sidebar d-flex">
                                    {/* sidebar close icon */}
                                    <span className="sidebar-close-icon" onClick={
                                        () => {
                                            setShowSibarLeft(false)
                                            setShowOverlay(false)
                                        }
                                    }>
                                        <i className="bx bx-x"/>
                                    </span>
                                    {/* sidebar close icon */}
                                    <div className="email-app-menu">
                                        <div className="form-group form-group-compose">
                                            {/* compose button  */}
                                            {
                                                permisos.filter(element => element.codigo === "add_visits").length > 0 &&
                                                <Button
                                                    text="Crear"
                                                    onChange={() => {
                                                        setShowSibarRight(!showSibarRight);
                                                        setShowSibarLeft(false);
                                                        setShowDetail(false);
                                                        setForm(initForm)
                                                        setVisitantSelected({
                                                            visitantes: [],
                                                            empleados: [],
                                                            proveedores: []
                                                        })
                                                        setShowOverlay(!showSibarRight);
                                                        setEstablishmentSelect([])
                                                    }}
                                                    theme="success"
                                                    classes=" btn-block my-2 compose-btn"
                                                />
                                            }
                                            {
                                                permisos.filter(element => element.codigo === "create_zone_visit").length > 0 &&
                                                <Button
                                                    text="Ingresar a tienda"
                                                    onChange={() => {
                                                        setEstablishmentZoneSelect([]);
                                                        setIdEstablishmentZonal(0);
                                                        get_employee_zone();
                                                        setModalZonal(true);
                                                    }}
                                                    theme="info"
                                                    classes=" btn-block my-2 compose-btn"
                                                />
                                            }

                                        </div>
                                        <div className="sidebar-menu-list">
                                            {/* sidebar menu  */}
                                            <div className="list-group list-group-messages">
                                                <SelectInput
                                                    id="filtro-visita"
                                                    name="filtro-visita"
                                                    options={filter_options}
                                                    onChange={filterToday}
                                                />
                                                <ul className="list-unstyled mb-0">

                                                    {
                                                        range_date.visible ? <>
                                                            <li className=" mr-2 mb-1">
                                                                <Input
                                                                    type="date"
                                                                    label="Fecha Inicio"
                                                                    onChange={handleFilterRangeDate}
                                                                    name='date_start'

                                                                />
                                                                <Input
                                                                    type="date"
                                                                    label="Fecha Fin"
                                                                    onChange={handleFilterRangeDate}
                                                                    name='date_end'

                                                                />
                                                            </li>
                                                            <hr/>
                                                        </> : null
                                                    }


                                                    <li className=" mr-2 mb-1">
                                                        <CheckBox
                                                            label="CREADAS"
                                                            name="check-creadas"
                                                            id="check-creadas"
                                                            onChange={(e) => {
                                                                handleFilterState(e, "CREADO");
                                                            }}
                                                            state={filtros.creadas}
                                                        />
                                                    </li>
                                                    <li className=" mr-2 mb-1">
                                                        <CheckBox
                                                            label="APROBADAS"
                                                            name="check-aprobadas"
                                                            id="check-aprobadas"
                                                            onChange={(e) => {
                                                                handleFilterState(e, "APROBADO");
                                                            }}
                                                            state={filtros.aprobadas}
                                                        />
                                                    </li>
                                                    <li className=" mr-2 mb-1">
                                                        <CheckBox
                                                            label="CADUCADAS"
                                                            name="check-caducadas"
                                                            id="check-caducadas"
                                                            onChange={(e) => {
                                                                handleFilterState(e, "CADUCADO");
                                                            }}
                                                            state={filtros.caducadas}
                                                        />
                                                    </li>
                                                    <li className=" mr-2 mb-1">
                                                        <CheckBox
                                                            label="EN ESPERA"
                                                            name="check-espera"
                                                            id="check-espera"
                                                            onChange={(e) => {
                                                                handleFilterState(e, "EN ESPERA");
                                                            }}
                                                            state={filtros.espera}
                                                        />
                                                    </li>
                                                    <li className=" mr-2 mb-1">
                                                        <CheckBox
                                                            label="PENDIENTE VISITA ZONAL"
                                                            name="check-pendiente-zonal"
                                                            id="check-pendiente-zona"
                                                            onChange={(e) => {
                                                                handleFilterState(e, "PENDIENTE VISITA ZONAL");
                                                            }}
                                                            state={filtros.pendiente_zonal}
                                                        />
                                                    </li>
                                                    <li className=" mr-2 mb-1">
                                                        <CheckBox
                                                            label="RECHAZADAS"
                                                            name="check-rechazadas"
                                                            id="check-rechazadas"
                                                            onChange={(e) => {
                                                                handleFilterState(e, "ANULADO");
                                                            }}
                                                            state={filtros.rechazadas}
                                                        />
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* sidebar label end */}
                                        </div>
                                    </div>
                                </div>
                                {/* User new mail right area */}
                                {

                                    permisos.filter(element => element.codigo === "add_visits").length > 0 ?
                                        <div className={`compose-new-mail-sidebar ${showSibarRight ? 'show' : ''}`}
                                             style={{
                                                 overflowY: 'scroll',
                                             }}
                                        >
                                            <div className="card shadow-none quill-wrapper p-0">
                                                <div className="card-header">
                                                    <h3 className="card-title" id="visita">
                                                        Nueva Visita
                                                    </h3>
                                                    <button type="button" className="close close-icon"
                                                            onClick={() => {
                                                                setShowSibarRight(false);
                                                                setShowSibarLeft(false);
                                                                setShowOverlay(false);
                                                            }}
                                                    >
                                                        <i className="bx bx-x"/>
                                                    </button>
                                                </div>
                                                {/* form start */}
                                                <form onSubmit={handleSubmit} id="compose-form">
                                                    <div className="card-content">
                                                        <div className="card-body pt-0">
                                                            <Input
                                                                type="text"
                                                                name="titulo"
                                                                id="titulo"
                                                                label="Título"
                                                                onChange={handleChange}
                                                                placeholder="Título"
                                                                value={form.titulo}
                                                            />
                                                            <TextArea
                                                                id="descripcion"
                                                                name="descripcion"
                                                                label="Motivo de la visita"
                                                                placeholder=""
                                                                onChange={handleChange}
                                                                rows={4}
                                                                value={form.descripcion}
                                                            />
                                                            <label className="form-label">Establecimientos</label>
                                                            {/*<SelectInput*/}
                                                            {/*    id="id_establecimiento"*/}
                                                            {/*    name="id_establecimiento"*/}
                                                            {/*    options={establishment}*/}
                                                            {/*    onChange={handleChange}*/}
                                                            {/*    select={form.establecimiento}*/}
                                                            {/*/>*/}

                                                            <Select
                                                                options={establishment}
                                                                isMulti={false}
                                                                onChange={handleChangeEstablishment}
                                                                value={establishment_select}
                                                            />
                                                            <br/>
                                                            <TextArea
                                                                id="observacion"
                                                                name="observacion"
                                                                label="Observación"
                                                                placeholder=""
                                                                onChange={handleChange}
                                                                rows={4}
                                                                value={form.observacion}
                                                            />
                                                            <Input
                                                                type="date"
                                                                name="fecha"
                                                                id="fecha"
                                                                label="Fecha"
                                                                onChange={handleChange}
                                                                placeholder=""
                                                                value={form.fecha}
                                                            />
                                                            <div className="form-group">
                                                                <label>Visitantes</label>
                                                                <Async
                                                                    isMulti={true}
                                                                    defaultOptions={false}
                                                                    loadOptions={promiseOptions}
                                                                    onChange={(e) => {
                                                                        handleChangeSelect(e)
                                                                    }}
                                                                    value={visitantSelectOptions}
                                                                />
                                                            </div>
                                                            <div className="chat-sidebar-list-wrapper pt-2">

                                                                <ul className="chat-sidebar-list">
                                                                    {
                                                                        visitantsSelected.empleados.map((item, index) => {
                                                                            return (
                                                                                <ItemChat title={"EMPLEADO"}
                                                                                          subtitle={item.nombres}
                                                                                          image={item.foto ? URLAPI + item.foto : window.location.origin + "/base/app-assets/images/profile/no_perfil.png"}
                                                                                          key={index}/>
                                                                            )
                                                                        })
                                                                    }
                                                                    {
                                                                        visitantsSelected.visitantes.map((item, index) => {
                                                                            return (
                                                                                <ItemChat title={"VISITANTE"}
                                                                                          subtitle={item.nombres}
                                                                                          image={item.foto ? URLAPI + item.foto : window.location.origin + "/base/app-assets/images/profile/no_perfil.png"}

                                                                                          key={index}/>
                                                                            )
                                                                        })
                                                                    }
                                                                    {
                                                                        visitantsSelected.proveedores.map((item, index) => {
                                                                            return (
                                                                                <ItemChat title={"PROVEEDOR"}
                                                                                          subtitle={item.nombres}
                                                                                          image={item.foto ? URLAPI + item.foto : window.location.origin + "/base/app-assets/images/profile/no_perfil.png"}
                                                                                          key={index}/>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="card-footer d-flex justify-content-end pt-0">
                                                        <Button
                                                            text="Cancelar"
                                                            theme="light-secondary"
                                                            type="reset"
                                                            classes="cancel-btn mr-1"
                                                            onChange={() => {
                                                                setShowSibarRight(false);
                                                                setShowOverlay(!showOverlay);
                                                            }}
                                                        />
                                                        <Button
                                                            text="Guardar"
                                                            onChange={() => {
                                                                //TODO: AGREGAR FUNCIONALIDAD DE CREAR VISITA
                                                            }}
                                                            theme="success"
                                                            type="submit"
                                                            disabled={isLoading}
                                                        />
                                                    </div>
                                                </form>
                                                {/* form start end*/}
                                            </div>
                                        </div> : null}
                                {/*/ User Chat profile right area */}
                            </div>
                        </div>
                        <div className="content-right">
                            <div className="content-overlay"/>
                            <div className="content-wrapper">
                                <div className="content-header row"/>
                                <div className="content-body">
                                    {/* email app overlay */}
                                    <div className={`app-content-overlay ${showOverlay ? 'show' : ''}`}
                                         onClick={
                                             () => {
                                                 setShowSibarRight(!showSibarRight);
                                                 setShowSibarLeft(false);
                                                 setShowDetail(false);
                                                 setShowOverlay(!showOverlay);
                                             }
                                         }
                                    />
                                    <div className="email-app-area">
                                        {/* Email list Area */}
                                        <div className="email-app-list-wrapper">
                                            <div className="email-app-list">
                                                <div className="email-action">
                                                    {/* action left start here */}

                                                    {/* action left end here *-/}

                      {/* action right start here */}
                                                    <div
                                                        className="action-right d-flex flex-grow-1 align-items-center justify-content-around">
                                                        {/* search bar  */}
                                                        <div className="email-fixed-search flex-grow-1">
                                                            <div className="sidebar-toggle d-block d-lg-none" onClick={
                                                                () => {
                                                                    setShowSibarLeft(!showSibarLeft);
                                                                    setShowOverlay(!showOverlay);
                                                                }
                                                            }>
                                                                <i className="bx bx-menu"/>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-10">
                                                                    <Search placeholder={"Buscar por establecimiento"}
                                                                            onChange={handleSearch}
                                                                    />
                                                                </div>
                                                                <div className="col-2">
                                                                    <Button
                                                                        text={<h3><i
                                                                            className="text-white bx bx-export"/></h3>}
                                                                        theme="success"
                                                                        type="button"
                                                                        classes="btn-lg round"
                                                                        onClick={() => {
                                                                            setModal(true);
                                                                            setFechaInicial('');
                                                                            setFechaFinal('');
                                                                        }}
                                                                    />
                                                                    {/* {!report ? <Button text={
                                                                        <h3><i className="text-white bx bx-export"/>
                                                                        </h3>
                                                                    } theme="success" type="button"
                                                                                       classes="btn-lg round"
                                                                                       onClick={() => {setModal(true); setFechaInicial(''); setFechaFinal('');}}
                                                                    /> : <div className="spinner-border text-success"
                                                                              role="status">
                                                                        <span className="sr-only">Loading...</span>
                                                                    </div>} */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* pagination and page count */}
                                                    </div>
                                                </div>
                                                {/* / action right */}

                                                <div className="row" style={{marginTop: 10, marginBottom: 5}}>
                                                    <div className="col-4">
                                                        <label className="ml-1">Página {page}</label>
                                                    </div>
                                                    <div className="col-8 text-right">
                                                        {(page > 1 && page <= n_page) &&
                                                            <button className="btn btn-primary mr-1" type="button"
                                                                    onClick={() => {
                                                                        less_data();
                                                                    }}><i className="bx bx-arrow-back"></i></button>
                                                        }
                                                        {(page >= 1 && page < n_page) &&
                                                            <button className="btn btn-success" type="button"
                                                                    onClick={() => {
                                                                        more_data();
                                                                    }}><i className="bx bx-right-arrow-alt"></i></button>
                                                        }
                                                    </div>
                                                </div>

                                                {/* email user list start */}
                                                <div className="email-user-list list-group">
                                                    <ul className="users-list-wrapper media-list" style={{
                                                        overflowY: 'scroll',
                                                    }}>
                                                        <InfiniteScroll
                                                            pageStart={0}
                                                            loadMore={() => loadFunc()}
                                                            hasMore={HasMore}
                                                            useWindow={false}

                                                            loader={
                                                                <div className="col-12 d-flex justify-content-center">
                                                                    <div
                                                                        className="spinner-border text-success"
                                                                        role="status"
                                                                    >
                                                                        <span className="sr-only">Loading...</span>
                                                                    </div>
                                                                    {" "}
                                                                </div>
                                                            }
                                                        >
                                                            {visits.map((item, index) => (
                                                                <ItemList1
                                                                    item={item}
                                                                    title={item.titulo}
                                                                    subtitle={item.establecimiento}
                                                                    rightDescription={`Visitantes: ${calculateVisitants(item)}`}
                                                                    bulletType={get_status(item.estado)}
                                                                    children={
                                                                        <div className="user-details div_fecha">
                                                                            {(item.fecha_solicitud_aprobacion) &&
                                                                                <div className="mail-items">
                                                                                    <span>Fecha de solicitud de aprobación: </span>{item.fecha_solicitud_aprobacion}
                                                                                </div>
                                                                            }
                                                                            {(item.fecha_respuesta_aprobacion) &&
                                                                                <div className="mail-meta-item">
                                                                                    <span>Fecha de aprobación: </span>{item.fecha_respuesta_aprobacion}
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                    onClick={onItemClickListener}
                                                                />
                                                            ))}
                                                        </InfiniteScroll>
                                                    </ul>
                                                    {/* email user list end */}

                                                    {/* no result when nothing to show on list */}
                                                    <div className="no-results">
                                                        <i className="bx bx-error-circle font-large-2"/>
                                                        <h5>No Items Found</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*/ Email list Area */}

                                        {/* Detailed Email View */}
                                        <div className={`email-app-details ${showDetail ? 'show' : ''} `}>
                                            {/* email detail view header */}
                                            <div className="email-detail-header">
                                                <div className="email-header-left d-flex align-items-center mb-1">
                                                    <span className="go-back mr-50" onClick={
                                                        () => {
                                                            setShowDetail(false);
                                                        }
                                                    }>
                                                        <span className="fonticon-wrap d-inline">
                                                        <i className="bx bx-arrow-back"/>
                                                        </span>
                                                    </span>
                                                    <h5 className="email-detail-title font-weight-normal mb-0">
                                                        {DataSelected.titulo}
                                                    </h5>
                                                </div>
                                                <div className="email-header-right mb-1 ml-2 pl-1">
                                                    <span
                                                        className={`badge badge-light-${get_status(DataSelected.estado)} badge-pill ml-1`}>
                                                        {DataSelected.estado}
                                                    </span>
                                                </div>
                                            </div>
                                            {/* email detail view header end*/}
                                            <div className="email-scroll-area" style={{overflowY: 'scroll',}}>
                                                {/* email details  */}
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="collapsible email-detail-head">
                                                            <Card
                                                                title={DataSelected.establecimiento}
                                                                description={
                                                                    <>
                                                                        {DataSelected.descripcion} <br/>
                                                                    </>
                                                                }
                                                                subtitle={DataSelected.fecha_visita}
                                                            >
                                                                <form className="row" onSubmit={handleAprove}>
                                                                    {(DataSelected.fecha_solicitud_aprobacion) &&
                                                                        <div className="col-12 mb-1">
                                                                            <h6>Fecha de solicitud de
                                                                                aprobación:</h6>{DataSelected.fecha_solicitud_aprobacion}
                                                                        </div>
                                                                    }
                                                                    {(DataSelected.fecha_respuesta_aprobacion) &&
                                                                        <div className="col-12 mb-1">
                                                                            <h6>Fecha de
                                                                                aprobación:</h6>{DataSelected.fecha_respuesta_aprobacion}
                                                                        </div>
                                                                    }
                                                                    <div className="col-md-8 col-12 mb-1">
                                                                        <h6>Observación:</h6>{DataSelected.observacion}
                                                                        <br/>
                                                                    </div>
                                                                    <div className="col-md-4 col-12 mb-1 text-right">
                                                                        <h6>Creado por:</h6>{DataSelected.usuario_crea}
                                                                    </div>
                                                                    {DataSelected.reason_decline_description && (
                                                                        <div className='col-12 mb-1'>
                                                                            <strong style={{color: "#475F7B"}}>Motivo de
                                                                                rechazo: </strong>
                                                                            {DataSelected.reason_decline_description}
                                                                        </div>
                                                                    )}
                                                                    {
                                                                        DataSelected.empleados != null
                                                                            ? DataSelected.empleados.map((item, index) => (
                                                                                <div className="col col-sm-12 col-md-6">

                                                                                    <CardItem data-toggle="modal"
                                                                                              data-target="#visitantModal"
                                                                                              onClick={
                                                                                                  () => {
                                                                                                      setVisitantModal({
                                                                                                          ...item,
                                                                                                          cargo: "Empleado"

                                                                                                      });
                                                                                                  }
                                                                                              }>
                                                                                        <>
                                                                                            <div className=" d-flex">
                                                                                                <div
                                                                                                    className="list-icon mr-1">
                                                                                                    <div
                                                                                                        className="avatar bg-rgba-primary m-0">
                                                                                                        <img
                                                                                                            className="img-fluid"
                                                                                                            src={item.foto ? URLAPI + item.foto : window.location.origin + "/base/app-assets/images/profile/no_perfil.png"
                                                                                                            }
                                                                                                            alt="img placeholder"
                                                                                                            height="38"
                                                                                                            width="38"/>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="list-content">
                                                                                                    <span
                                                                                                        className="list-title text-bold-500">{item.nombres} {item.apellidos}</span>
                                                                                                    <small
                                                                                                        className="text-muted d-block">Empleado</small>
                                                                                                    <small
                                                                                                        className="text-muted d-block">{item.ruc}</small>

                                                                                                </div>
                                                                                            </div>

                                                                                        </>

                                                                                    </CardItem>
                                                                                </div>

                                                                            )) : null

                                                                    }
                                                                    {
                                                                        DataSelected.visitantes != null
                                                                            ? DataSelected.visitantes.map((item, index) => (
                                                                                <div className="col col-sm-12 col-md-6">

                                                                                    <CardItem data-toggle="modal"
                                                                                              data-target="#visitantModal"
                                                                                              onClick={
                                                                                                  () => {
                                                                                                      setVisitantModal({
                                                                                                          ...item,
                                                                                                          cargo: "Visitante"

                                                                                                      });
                                                                                                  }
                                                                                              }>
                                                                                        <>
                                                                                            <div className=" d-flex">
                                                                                                <div
                                                                                                    className="list-icon mr-1">
                                                                                                    <div
                                                                                                        className="avatar bg-rgba-primary m-0">
                                                                                                        <img
                                                                                                            className="img-fluid"
                                                                                                            src={item.foto ? URLAPI + item.foto : window.location.origin + "/base/app-assets/images/profile/no_perfil.png"
                                                                                                            }
                                                                                                            alt="img placeholder"
                                                                                                            height="38"
                                                                                                            width="38"/>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="list-content">
                                                                                                    <span
                                                                                                        className="list-title text-bold-500">{item.nombres} {item.apellidos}</span>
                                                                                                    <small
                                                                                                        className="text-muted d-block">VISITANTE</small>
                                                                                                    <small
                                                                                                        className="text-muted d-block">{item.ruc}</small>

                                                                                                </div>
                                                                                            </div>

                                                                                        </>
                                                                                    </CardItem>
                                                                                </div>

                                                                            )) : null
                                                                    }
                                                                    {
                                                                        DataSelected.proveedores != null
                                                                            ? DataSelected.proveedores.map((item, index) => (
                                                                                <div className="col col-sm-12 col-md-6">

                                                                                    <CardItem data-toggle="modal"
                                                                                              data-target="#visitantModal"
                                                                                              onClick={
                                                                                                  () => {
                                                                                                      setVisitantModal({
                                                                                                          ...item,
                                                                                                          nombres: item.nombres,
                                                                                                          apellidos: "",
                                                                                                          cargo: "Proveedor"

                                                                                                      });
                                                                                                  }
                                                                                              }>
                                                                                        <>
                                                                                            <div className=" d-flex">
                                                                                                <div
                                                                                                    className="list-icon mr-1">
                                                                                                    <div
                                                                                                        className="avatar bg-rgba-primary m-0">
                                                                                                        <img
                                                                                                            className="img-fluid"
                                                                                                            src={item.foto ? URLAPI + item.foto : window.location.origin + "/base/app-assets/images/profile/no_perfil.png"
                                                                                                            }
                                                                                                            alt="img placeholder"
                                                                                                            height="38"
                                                                                                            width="38"/>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="list-content">
                                                                                                    <span
                                                                                                        className="list-title text-bold-500">{item.nombres}</span>
                                                                                                    <small
                                                                                                        className="text-muted d-block">PROVEEDOR</small>
                                                                                                    <small
                                                                                                        className="text-muted d-block">{item.ruc}</small>

                                                                                                </div>
                                                                                            </div>

                                                                                        </>
                                                                                    </CardItem>
                                                                                </div>

                                                                            )) : null

                                                                    }
                                                                    <div className="col-12 div-flex mt-2 mb-5">
                                                                        {
                                                                            permisos.filter(item => item.codigo === "can_approve_visit").length > 0 && DataSelected.estado === "EN ESPERA" ? (
                                                                                <Button
                                                                                    text="Aprobar"
                                                                                    onChange={
                                                                                        () => {
                                                                                            handleClickAprove("APROBADO");
                                                                                        }
                                                                                    }
                                                                                    theme="success"
                                                                                    classes="button_div1"
                                                                                    type="submit"
                                                                                    disabled={isLoading}
                                                                                />
                                                                            ) : null
                                                                        }

                                                                        {
                                                                            permisos.filter(item => item.codigo === "can_approve_visit").length > 0 && DataSelected.estado === "EN ESPERA" ? (
                                                                                <Button
                                                                                    text="Rechazar"
                                                                                    onChange={
                                                                                        () => {
                                                                                            handleClickAprove("ANULADO");
                                                                                        }
                                                                                    }
                                                                                    theme="danger"
                                                                                    classes="button_div1"
                                                                                    type="submit"
                                                                                    disabled={isLoading}
                                                                                />
                                                                            ) : null
                                                                        }
                                                                        {

                                                                            permisos.filter(item => item.codigo === "change_visits").length > 0 ? (
                                                                                <Button
                                                                                    text="Eliminar"
                                                                                    onChange={handleDelete}
                                                                                    theme="outline-danger"
                                                                                    classes="button_div1"
                                                                                    type="button"
                                                                                    disabled={isLoading}
                                                                                />
                                                                            ) : null
                                                                        }
                                                                        {

                                                                            permisos.filter(item => item.codigo === "change_visits").length > 0 ? (
                                                                                <Button
                                                                                    text="Editar"
                                                                                    onChange={
                                                                                        () => {
                                                                                            handleEdit(DataSelected)
                                                                                            setShowSibarRight(true)
                                                                                            setShowOverlay(true)
                                                                                        }
                                                                                    }
                                                                                    theme="warning"
                                                                                    classes="button_div1"
                                                                                    type="button"
                                                                                    disabled={isLoading}
                                                                                />
                                                                            ) : null
                                                                        }
                                                                    </div>


                                                                </form>
                                                            </Card>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* email details  end*/}
                                                <div className="row px-2 mb-4">
                                                    {/* quill editor for reply message */}
                                                    <div className="col-12 px-0"/>
                                                </div>
                                            </div>
                                        </div>
                                        {/*/ Detailed Email View */}
                                        <VisitantModal item={visitantmodal}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal-main div-modal" show={modal}>
                <form onSubmit={handleGenerateReport}>
                    <div className="card-body">
                        <h5 className="mb-2">Exportar datos</h5>
                        <div className="form-group">
                            <Input
                                type="date"
                                label="Fecha Inicio"
                                onChange={(e) => {
                                    setFechaInicial(e.target.value)
                                }}
                                name='fecha_inicial'
                                value={fecha_inicial}
                            />
                            <Input
                                type="date"
                                label="Fecha Fin"
                                onChange={(e) => {
                                    setFechaFinal(e.target.value)
                                }}
                                name='fecha_final'
                                value={fecha_final}
                            />
                            <Input
                                label="Colaborador"
                                type="text"
                                onChange={(e) => {
                                    setEmpleado(e.target.value)
                                }}
                                name='empleado'
                                value={empleado}
                            />
                        </div>
                    </div>
                    <div className="card-footer text-center">
                        <button className={`btn btn-success mb-1 button_div`} disabled={isLoading}>Exportar</button>
                        <button type="button" onClick={() => {
                            setFechaInicial('');
                            setFechaFinal('');
                            setModal(false);
                        }} className={`btn btn-outline-danger button_div`}>Cancelar
                        </button>
                    </div>
                </form>
            </Modal>
            <Modal className="modal-main div-modal" show={modalZonal}>
                <form onSubmit={createZonalVisit}>
                    <div className="card-body">
                        <div>
                            <h5 className="mb-2">Ingresar a tienda</h5>
                        </div>
                        <div>
                            <small>Empleado</small>
                            <p>{employee_zonal}</p>
                        </div>
                        <label className="form-label">Establecimientos</label>
                        <Select
                            options={establecimiento_zonal}
                            isMulti={false}
                            onChange={handleChangeEstablishmentZone}
                            value={establishment_zone_select}
                        />
                    </div>
                    <div className="card-footer text-center">
                        <button className={`btn btn-success mb-1 button_div`} disabled={isLoading}>Aceptar</button>
                        <button type="button" onClick={() => {
                            setModalZonal(false);
                        }} className={`btn btn-outline-danger button_div`}>Cancelar
                        </button>
                    </div>
                </form>
            </Modal>
        </div>
    );
};

export default VisitasAdmin;
