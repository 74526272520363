import React, {useState, useEffect} from 'react';
import CardIncome, {theme} from '../../../components/card-income';
import Button from '../../../components/button';
import {useHistory} from 'react-router-dom'
import Breadcrumbs from "../../../components/breadcrumbs";
import {useDispatch, useSelector} from "react-redux";
import {ToastContainer, toast} from 'react-toastify';
import {CREATE_INCOME, clearIncome, createIncome, getAllIncome} from '../../../redux/income';
import LoadingSpinner from "../../../components/loading-spinner";

const ListIncome = () => {
    let dispatch = useDispatch();
    let history = useHistory();

    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const [disponible, setDisponible] = useState(false);
    const [data, setData] = useState([]);
    const [establishment_select, setEstablishmentSelect] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const _session_data = useSelector((state) => state.users_module._session_data);

    const _get_all_income = useSelector(
        (state) => state.income_module._get_all_income
    );

    const userEstablishmentsList = useSelector(
        (state) => state.users_module._session_data.data
    );

    const _create_income = useSelector((state) => state.income_module._create_income);

    useEffect(() => {
        if (_session_data && _session_data.data && _session_data.data.establecimientos !== undefined) {
            if (_session_data.data.establecimientos.length !== 0) {
                setEstablishmentSelect(_session_data.data.establecimientos[0].id)
            }
        }
    }, [_session_data]);

    useEffect(() => {
        dispatch(getAllIncome(
            token,
            userEstablishmentsList.establecimientos?.map((par) => par.id),
            1
        ));
        // eslint-disable-next-line
    }, [token, dispatch, userEstablishmentsList]);

    // eslint-disable-next-line
    const today = new Date();

    useEffect(() => {
        if (_get_all_income && _get_all_income.data && _get_all_income.data.datos) {
            if (_get_all_income.data.datos.length > 0) {
                let value = _get_all_income.data.datos.filter(item => new Date(item.fecha + ' 00:00:00').toDateString() === today.toDateString() && parseInt(item.establecimiento_id) === parseInt(establishment_select))
                setData(value);
                if (value.length > 0) {
                    setDisponible(true);
                }
            }
        }
        // eslint-disable-next-line
    }, [_get_all_income, establishment_select]);

    useEffect(() => {
        if (_create_income.status === 201) {
            dispatch(getAllIncome(token, userEstablishmentsList.establecimientos?.map((par) => par.id), 1));
            toast.success("Ingreso creado", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearIncome(CREATE_INCOME));
            setIsLoading(false);
        } else if (_create_income.status !== 0) {
            toast.error(_create_income.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearIncome(CREATE_INCOME));
            setIsLoading(false);
        }
    }, [_create_income, dispatch, token, userEstablishmentsList]);

    const handleSubmit = (e) => {
        setIsLoading(true);
        e.preventDefault();
        dispatch(createIncome(token, {id_establecimiento: establishment_select}))
    }

    return (
        <div className="app-content content">
            <div className="content-overlay"/>
            <ToastContainer/>
            {isLoading && <LoadingSpinner/>}
            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Ingreso tienda"
                            items={[{label: "Ingreso tienda", link: "/ingreso-tienda"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row d-flex justify-content-end">
                        <Button theme="success" text="Registrar ingreso" disabled={disponible} onClick={handleSubmit}/>
                    </div>
                    <div className="row">
                        {data.length > 0 ? (
                            data.map((item) => (
                                <div className="col col-xl-3 col-md-4 col-sm-6 col-xs-12">
                                    <CardIncome
                                        theme={theme(item.estado)}
                                        title={item.establecimiento}
                                        subtitle={new Date(item.fecha + ' 00:00:00').toLocaleDateString()}
                                        description={item.estado}
                                        button_text="Ver"
                                        onClick={() => {
                                            history.push(`/ingreso-tienda/${item.id}`)
                                        }}
                                    />
                                </div>
                            ))
                        ) : (
                            <div className="col-12">
                                <h3 className="text-bold-400">
                                    No existe registro de ingreso para la fecha actual
                                </h3>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ListIncome;
