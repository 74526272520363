import React, {useEffect, useState} from 'react';
import DataTable from "../../../components/data-table";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useSelector, useDispatch} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {getAllJobs} from "../../../redux/jobs";

const Jobs = (props) => {
    let history = useHistory();
    const dispatch = useDispatch();

    const {token} = useSelector((state) => state.users_module.login.data) || null;
    const _jobs = useSelector((state) => state.jobs_module._get_all_jobs);

    const [data, setData] = useState([]);
    const [permisos, setPermisos] = useState([]);
    const [actions, setActions] = useState([]);

    useEffect(() => {
        dispatch(getAllJobs(token));
    }, [token, dispatch]);

    useEffect(() => {
        if (_jobs && _jobs.data && _jobs.data.datos) {
            setData(_jobs.data.datos);
        }
    }, [_jobs]);

    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);
        }
    }, [props]);

    useEffect(() => {
        let act = []
        if (permisos) {
            if (permisos.filter(x => x.codigo === 'change_job').length > 0) {
                act.push({
                    name: "edit",
                    label: "Edit",
                    icon: "bx bx-edit",
                    color: "primary",
                    onClick: (item) => {
                        history.push(`/cargos/editar/${item.id}`);
                    },
                })

            }
        }
        setActions(act);
    }, [permisos, history]);

    return (<div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Cargos"
                            items={[{label: "Cargos", link: "/cargos"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                {
                                    permisos.filter(x => x.codigo === 'add_job').length > 0 ?
                                        <Link to="/cargos/crear" className="btn btn-success">
                                            Crear
                                        </Link> : null
                                }

                                <div className="table-responsive">
                                    <DataTable
                                        dataTable={data}
                                        columns={[
                                            {
                                                name: "nombre",
                                                label: "Nombre",
                                                type: "text",
                                            },
                                            {
                                                name: "visita_no_programada",
                                                label: "Visita no programada",
                                                type: "boolean",
                                            },
                                            {
                                                name: "enviar_notificacion",
                                                label: "Enviar notificación",
                                                type: "boolean",
                                            },
                                            {
                                                name: "activo",
                                                label: "Activo",
                                                type: "boolean",
                                            },

                                        ]}
                                        actions={actions}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Jobs;
