import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {URLAPI, get_status} from "../../../config";
import Card from "../../../components/card";
import {clearVisit, updateVisit, UPDATE_VISIT, getAllVisits, visitRequiredExit} from '../../../redux/visits';
import {useDispatch, useSelector} from "react-redux";
import CardItem from "../../../components/card-item";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import VisitantModal from "./visitant-modal";
import {toast, ToastContainer} from 'react-toastify';
import LoadingSpinner from "../../../components/loading-spinner";

const VisitDetails = (props) => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const {token} = useSelector((state) => state.users_module.login.data) || null;
    const _get_all_visits = useSelector((state) => state.visits_module._get_all_visits);

    const userEstablishmentsList = useSelector(
        (state) => state.users_module._session_data.data
    );

    const _update_visit = useSelector
    ((state) => state.visits_module._update_visit);
    const [DataSelected, setDataSelected] = useState(
        {
            id: 0,
            fecha_creacion: "",
            titulo: "",
            descripcion: "",
            observacion: "",
            reason_decline_description: "",
            usuario_cre: "",
            visita_programada: true,
            fecha_visita: "",
            establecimiento: "",
            estado: "",
            usuario_crea: "",
            empleados: [],
            visitantes: [],
            proveedores: []
        }
    );

    const [isLoading, setIsLoading] = useState(false);

    const [visitantSelected, setVisitantSelected] = useState(
        {
            ruc: '0000001',
            nombres: 'Denis',
            apellidos: 'TOBAR',
            empresa: "",
            puesto: "",
            foto: '/media/employees_photo/photo-1494790108377-be9c29b29330.jpeg'
        }
    )

    useEffect(() => {
        if (_update_visit.status === 200) {
            dispatch(clearVisit(UPDATE_VISIT));
            toast.success(_update_visit.message);
            dispatch(getAllVisits(token, userEstablishmentsList.establecimientos?.map((par) => par.id), 1));
            setIsLoading(false);
        } else if (_update_visit.status !== 0) {
            toast.error(_update_visit.message);
            dispatch(clearVisit(UPDATE_VISIT));
            setIsLoading(false);
        }
    }, [_update_visit, dispatch, token, userEstablishmentsList])

    useEffect(() => {
        if (_get_all_visits && _get_all_visits.data && _get_all_visits.data.datos) {
            if (_get_all_visits.data.datos.length > 0) {
                setDataSelected(_get_all_visits.data.datos.find(visit => visit.id === parseInt(id)));
            }
        }
    }, [_get_all_visits, id, DataSelected])


    const handleAprove = () => {
        // e.preventDefault();
        setDataSelected({...DataSelected, estado: "EN ESPERA"});
        let data = {estado: "EN ESPERA"};
        setIsLoading(true);
        dispatch(updateVisit(token, DataSelected.id, data));
    }
    const handleRequireExit = () => {
        // e.preventDefault();
        setIsLoading(true);
        setDataSelected({...DataSelected, estado: "SALIDA REQUERIDA"});
        // let data = {estado: "EN ESPERA"}
        dispatch(visitRequiredExit(token, DataSelected.id));
    }

    return (
        <div className="app-content content">
            <div className="content-overlay"/>
            <ToastContainer/>
            {isLoading && <LoadingSpinner/>}
            <div className="content-wrapper">

                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 ">

                        <Breadcrumbs
                            title="Visitas"
                            items={[{label: "Listado Visitas", link: "/visitas"}]}
                        />
                    </div>

                </div>
                <div className="content-body">
                    {/* email app overlay */}
                    <div className="app-content-overlay"/>
                    <div className="email-app-area">
                        {/* Email list Area */}
                        <div className="email-app-list-wrapper">
                            <div className="email-app-list">
                                <div className="email-action">
                                    {/* action left start here */}

                                    {/* action left end here *-/}

                      {/* action right start here */}
                                    <div
                                        className="action-right d-flex flex-grow-1 align-items-center justify-content-around">
                                        {/* search bar  */}

                                        {/* pagination and page count */}
                                    </div>
                                </div>
                                {/* / action right */}


                            </div>
                        </div>
                        {/*/ Email list Area */}

                        {/* Detailed Email View */}
                        <div className="email-app-details" style={{
                            borderStyle: "solid",
                            borderWidth: "1px",
                            borderColor: "#DFE3E7",
                        }}>
                            {/* email detail view header */}
                            <div className="navbar navbar-light bg-white p-2"
                                 style={{
                                     borderBottom: "1px solid #DFE3E",
                                 }}
                            >

                                <div className="email-header-left d-flex align-items-center mb-1">
                                    <Link to="/visitas" className="go-back mr-50">
                                        <span className="fonticon-wrap d-inline">
                                            <i className="bx bx-arrow-back"/>
                                        </span>
                                    </Link>
                                    <h5 className="email-detail-title font-weight-normal mb-0">
                                        {DataSelected.titulo}
                                    </h5>
                                </div>
                                <div className="email-header-right mb-1 ml-2 pl-1">
                                    <span
                                        className={`badge badge-light-${get_status(DataSelected.estado)} badge-pill ml-1`}>
                                        {DataSelected.estado}
                                    </span>
                                </div>
                            </div>
                            {/* email detail view header end*/}
                            <div className="email-scroll-area">
                                {/* email details  */}
                                <div className="row">
                                    <div className="col-12 p-3">
                                        <div className="collapsible email-detail-head">
                                            <Card
                                                title={DataSelected.establecimiento}
                                                description={
                                                    <>
                                                        <span>Fecha de visita: </span><strong>{new Date(DataSelected.fecha_visita + " 00:00:00").toLocaleDateString("es-ES", {
                                                        weekday: 'long',
                                                        year: 'numeric',
                                                        month: 'long',
                                                        day: 'numeric'
                                                    })}</strong><br/>
                                                        {DataSelected.descripcion} <br/>
                                                    </>
                                                }
                                                subtitle={
                                                    DataSelected.estado === "CREADO" ? (
                                                        <div className="col-12 d-flex justify-content-end">
                                                            <Button
                                                                text="Enviar"
                                                                onChange={
                                                                    () => {
                                                                        handleAprove();
                                                                    }
                                                                }
                                                                theme="success"
                                                                type="button"
                                                                disabled={isLoading}
                                                            />
                                                        </div>
                                                    ) : DataSelected.estado === "APROBADO" ? (
                                                        <div className='col-12 d-flex justify-content-end'>
                                                            <Button
                                                                text='Solicitar apertura'
                                                                onChange={handleRequireExit}
                                                                theme='success'
                                                                type='button'
                                                                disabled={isLoading}
                                                            />
                                                        </div>
                                                    ) : null
                                                }
                                            >
                                                <form className="row">
                                                    {(DataSelected.fecha_solicitud_aprobacion) &&
                                                        <div className="col-12 mb-1">
                                                            <h6>Fecha de solicitud de
                                                                aprobación:</h6>{DataSelected.fecha_solicitud_aprobacion}
                                                        </div>
                                                    }
                                                    {(DataSelected.fecha_respuesta_aprobacion) &&
                                                        <div className="col-12 mb-1">
                                                            <h6>Fecha de
                                                                aprobación:</h6>{DataSelected.fecha_respuesta_aprobacion}
                                                        </div>
                                                    }
                                                    <div className="col-md-8 col-12 mb-1">
                                                        <h6>Observación:</h6>{DataSelected.observacion} <br/>
                                                    </div>
                                                    <div className="col-md-4 col-12 mb-1 text-right">
                                                        <h6>Creado por:</h6>{DataSelected.usuario_crea}
                                                    </div>
                                                    {DataSelected.reason_decline_description && (
                                                        <div className='col-12 mb-1'>
                                                            <strong style={{color: "#475F7B"}}>Motivo de
                                                                rechazo: </strong>
                                                            {DataSelected.reason_decline_description}
                                                        </div>
                                                    )}

                                                    {
                                                        DataSelected.empleados != null
                                                            ? DataSelected.empleados.map((item, index) => (
                                                                <div className="col col-sm-12 col-md-6">

                                                                    <CardItem data-toggle="modal"
                                                                              data-target="#visitantModal"
                                                                              onClick={
                                                                                  () => {
                                                                                      setVisitantSelected(
                                                                                          {
                                                                                              ...item,
                                                                                              cargo: "Empleado"

                                                                                          });
                                                                                  }
                                                                              }
                                                                    >
                                                                        <>
                                                                            <div className=" d-flex">
                                                                                <div className="list-icon mr-1">
                                                                                    <div
                                                                                        className="avatar bg-rgba-primary m-0">
                                                                                        <img className="img-fluid"
                                                                                             src={item.foto ? URLAPI + item.foto : window.location.origin + "/base/app-assets/images/profile/no_perfil.png"}
                                                                                             alt="img placeholder"
                                                                                             height="38" width="38"/>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="list-content">
                                                                                    <span
                                                                                        className="list-title text-bold-500">{item.nombres} {item.apellidos}</span>
                                                                                    <small
                                                                                        className="text-muted d-block">Empleado</small>
                                                                                    <small
                                                                                        className="text-muted d-block">{item.ruc}</small>

                                                                                </div>
                                                                            </div>

                                                                        </>

                                                                    </CardItem>
                                                                </div>

                                                            )) : null

                                                    }
                                                    {
                                                        DataSelected.visitantes != null
                                                            ? DataSelected.visitantes.map((item, index) => (
                                                                <div className="col col-sm-12 col-md-6">

                                                                    <CardItem data-toggle="modal"
                                                                              data-target="#visitantModal"
                                                                              onClick={
                                                                                  () => {
                                                                                      setVisitantSelected({
                                                                                          ...item,
                                                                                          empresa: "",
                                                                                          puesto: "",
                                                                                          cargo: "Visitante"

                                                                                      });
                                                                                  }
                                                                              }
                                                                    >
                                                                        <>
                                                                            <div className=" d-flex">
                                                                                <div className="list-icon mr-1">
                                                                                    <div
                                                                                        className="avatar bg-rgba-primary m-0">
                                                                                        <img className="img-fluid"
                                                                                             src={item.foto ?
                                                                                                 URLAPI + item.foto :
                                                                                                 window.location.origin + "/base/app-assets/images/profile/no_perfil.png"}
                                                                                             alt="img placeholder"
                                                                                             height="38" width="38"/>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="list-content">
                                                                                    <span
                                                                                        className="list-title text-bold-500">{item.nombres} {item.apellidos}</span>
                                                                                    <small
                                                                                        className="text-muted d-block">VISITANTE</small>
                                                                                    <small
                                                                                        className="text-muted d-block">{item.ruc}</small>

                                                                                </div>
                                                                            </div>

                                                                        </>
                                                                    </CardItem>
                                                                </div>

                                                            )) : null

                                                    }
                                                    {
                                                        DataSelected.proveedores != null
                                                            ? DataSelected.proveedores.map((item, index) => (
                                                                <div className="col col-sm-12 col-md-6">

                                                                    <CardItem data-toggle="modal"
                                                                              data-target="#visitantModal"
                                                                              onClick={
                                                                                  () => {
                                                                                      setVisitantSelected({
                                                                                          ...item,
                                                                                          nombres: item.nombres,
                                                                                          apellidos: "",
                                                                                          empresa: "",
                                                                                          puesto: "",
                                                                                          cargo: "Proveedor"

                                                                                      });
                                                                                  }
                                                                              }>
                                                                        <>
                                                                            <div className=" d-flex">
                                                                                <div className="list-icon mr-1">
                                                                                    <div
                                                                                        className="avatar bg-rgba-primary m-0">
                                                                                        <img className="img-fluid"
                                                                                             src={item.foto ? URLAPI + item.foto : window.location.origin + "/base/app-assets/images/profile/no_perfil.png"
                                                                                             } alt="img placeholder"
                                                                                             height="38" width="38"/>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="list-content">
                                                                                    <span
                                                                                        className="list-title text-bold-500">{item.nombres}</span>
                                                                                    <small
                                                                                        className="text-muted d-block">PROVEEDOR</small>
                                                                                    <small
                                                                                        className="text-muted d-block">{item.ruc}</small>

                                                                                </div>
                                                                            </div>

                                                                        </>
                                                                    </CardItem>
                                                                </div>

                                                            )) : null

                                                    }
                                                </form>
                                            </Card>
                                        </div>
                                    </div>
                                </div>
                                {/* email details  end*/}
                                <div className="row px-2 mb-4">
                                    {/* quill editor for reply message */}
                                    <div className="col-12 px-0"/>
                                </div>
                            </div>
                        </div>
                        {/*/ Detailed Email View */}
                    </div>
                    <VisitantModal item={visitantSelected}/>
                </div>
            </div>
        </div>
    )

}

export default VisitDetails;
