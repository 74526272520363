import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import SelectInput from "../../../components/select";
import {Link, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import CheckBox from "../../../components/check-box";
import {
    clear_employee,
    createEmployee,
    CREATE_EMPLOYEE,
    getAllEmployees
} from "../../../redux/employees";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from "../../../components/loading-spinner";
import {get_all_companies} from "../../../redux/company";
import {getAllJobs} from "../../../redux/jobs";
import {get_all_establishments} from "../../../redux/establishment";
import {get_all_bank} from "../../../redux/banks";

/** @module  Pages/Employee/CreateEmployee */

const CreateEmployee = () => {
    /* A hook that allows us to dispatch actions to the store. */
    const dispatch = useDispatch();
    let history = useHistory();

    /* A state variable that is used to store the form data. */
    const [form, setForm] = useState({
        ruc: "",
        nombres: "",
        apellidos: "",
        id_cargo: 0,
        id_empresa: 0,
        id_establecimiento: 0,
        id_banco: 0,
        numero_cuenta: "",
        tipo_cuenta: "",
        tipo_jornada: "",
        imagen: "",
        correo: "",
        telefono: "",
        fecha_ingreso: "",
        nuevo_empleado: false,
        correos_adicionales: []
    });

    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        dispatch(get_all_companies(token));
        dispatch(getAllJobs(token));
        dispatch(get_all_establishments(token));
        dispatch(get_all_bank(token));
    }, [token, dispatch]);

    const _companies = useSelector(
        (state) => state.company_module._get_all_companies
    );

    const _establishment = useSelector(
        (state) => state.establishment_module._get_all_establishments
    );

    const _get_all_bank = useSelector(
        (state) => state.bank_module._get_all_bank
    );

    const _create_employee = useSelector((state) => state.employees_module._create_employee);

    const _jobs = useSelector((state) => state.jobs_module._get_all_jobs);

    const [companies, setCompanies] = useState([]);
    const [establishments, setEstablishments] = useState([]);
    const [banks, setBanks] = useState([]);

    useEffect(() => {
        if (_companies && _companies.data && _companies.data.datos) {
            setCompanies(_companies.data.datos);
        }
    }, [_companies]);

    useEffect(() => {
        if (_establishment && _establishment.data && _establishment.data.datos) {
            setEstablishments(_establishment.data.datos);
        }
    }, [_establishment]);

    useEffect(() => {
        if (_get_all_bank && _get_all_bank.data && _get_all_bank.data.datos) {
            let filter = _get_all_bank.data.datos.filter(x => x.activo === true);
            setBanks(filter);
        }
    }, [_get_all_bank]);

    /* A state variable that is used to store the form data. */
    useEffect(() => {
        if (_create_employee.status === 201) {
            toast.success("El empleado ha sido creado correctamente");
            dispatch(clear_employee(CREATE_EMPLOYEE));
            history.push("/empleados");
            setIsLoading(false);
            dispatch(getAllEmployees(token));

        } else {
            if (_create_employee.status !== 0) {
                toast.error(_create_employee.message);
                setIsLoading(false);
            }
        }
    }, [_create_employee, dispatch, history, token]);

    useEffect(() => {
    }, []);

    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        if (_jobs && _jobs.data && _jobs.data.datos) {
            let cargos = _jobs.data.datos.filter(x => x.activo === true)
            setJobs(cargos);
        }
    }, [_jobs]);

    const handleChange = (e) => {
        if (e.target.name === 'nuevo_empleado') {
            setForm({
                ...form,
                [e.target.name]: e.target.checked,
            });
        } else if (e.target.name === "id_cargo" || e.target.name === "id_empresa" || e.target.name === "id_establecimiento" || e.target.name === "id_banco") {
            setForm({
                ...form,
                [e.target.name]: parseInt(e.target.value),
            });
            if (e.target.name === "id_empresa") {
                document.getElementById("id_establecimiento").selectedIndex = 0;
                let v_establishment = _establishment.data.datos.filter(x => parseInt(e.target.value) === parseInt(x.id_empresa));
                setEstablishments(v_establishment);
            }
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleChangeEmail = (e) => {
        let correos = form.correos_adicionales;
        // eslint-disable-next-line
        correos.map(c => {
            if (c.label === e.target.name) {
                c.value = e.target.value
            }
        })

        setForm({
            ...form,
            correos_adicionales: correos
        })
    }

    const handleFileChange = (e) => {
        setForm({
            ...form,
            imagen: e.target.files[0],
        });
    };

    const [count, setCount] = useState(1);

    function add_email() {
        const c = "Correo" + count;
        const correos = form.correos_adicionales;
        correos.push({'label': c, 'value': ''});
        setForm({
            ...form,
            correos_adicionales: correos,
        });
        setCount(count + 1);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        //validate fields
        if (form.ruc === "") {
            toast.error("Debe ingresar el ruc");
            setIsLoading(false);
            return;
        }
        if (form.nombre === "") {
            toast.error("Debe ingresar el nombre");
            setIsLoading(false);
            return;
        }
        if (form.apellidos === "") {
            toast.error("Debe ingresar el apellido");
            setIsLoading(false);
            return;
        }
        if (form.correo === "") {
            toast.error("Debe ingresar el correo electrónico");
            setIsLoading(false);
            return;
        }
        if (form.id_cargo === 0) {
            toast.error("Debe seleccionar el cargo");
            setIsLoading(false);
            return;
        }
        if (form.id_empresa === 0) {
            toast.error("Debe seleccionar la empresa");
            setIsLoading(false);
            return;
        }
        if (form.id_establecimiento === 0) {
            toast.error("Debe seleccionar el establecimiento");
            setIsLoading(false);
            return;
        }
        if (form.tipo_jornada === "") {
            toast.error("Debe seleccionar tipo de jornada");
            setIsLoading(false);
            return;
        }
        dispatch(createEmployee(token, form));
    };

    return (
        <div className="app-content content">
            <ToastContainer/>
            {isLoading && <LoadingSpinner/>}
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Empleados"
                            items={[
                                {label: "Empleados", link: "/empleados"},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Crear Empleado</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <h5>Información Adicional</h5>
                                        </div>
                                        <div className="col-md-4">
                                            <Input
                                                label="Ruc"
                                                name="ruc"
                                                onChange={handleChange}
                                                value={form.ruc}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <Input
                                                label="Nombre"
                                                name="nombres"
                                                onChange={handleChange}
                                                value={form.nombre}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <Input
                                                label="Apellidos"
                                                name="apellidos"
                                                onChange={handleChange}
                                                value={form.apellidos}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <Input
                                                label="Correo"
                                                name="correo"
                                                onChange={handleChange}
                                                value={form.correo}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <Input
                                                label="Teléfono"
                                                name="telefono"
                                                onChange={handleChange}
                                                value={form.telefono}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <Input
                                                label="Imagen"
                                                name="imagen"
                                                type="file"
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12">
                                            <h5>Información del cargo</h5>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Cargo</label>
                                                <SelectInput
                                                    label="Cargo"
                                                    name="id_cargo"
                                                    options={jobs.map((job) => ({
                                                        name: job.nombre,
                                                        id: job.id,
                                                    }))}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Empresa</label>
                                                <SelectInput
                                                    label="Empresa"
                                                    name="id_empresa"
                                                    options={companies.map((company) => ({
                                                        name: company.nombre,
                                                        id: company.id,
                                                    }))}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Establecimiento</label>
                                                <SelectInput
                                                    id="id_establecimiento"
                                                    label="establecimiento"
                                                    name="id_establecimiento"
                                                    options={establishments.map((item) => ({
                                                        name: item.nombre,
                                                        id: item.id,
                                                    }))}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <label>Tipo de jornada</label>
                                            <select
                                                className='form-control'
                                                name='tipo_jornada'
                                                required={true}
                                                onChange={handleChange}
                                            >
                                                <option value={0} hidden>Seleccione un tipo de jornada</option>
                                                <option value="complete"
                                                        selected={form.tipo_jornada === 'complete'}>Completa
                                                </option>
                                                <option value="partial"
                                                        selected={form.tipo_jornada === 'partial'}>Parcial
                                                </option>
                                            </select>
                                        </div>
                                        <div className="col-md-4">
                                            <Input
                                                type="date"
                                                label="Fecha Ingreso"
                                                name="fecha_ingreso"
                                                onChange={handleChange}
                                                value={form.fecha_ingreso}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12">
                                            <h5>Información bancaria</h5>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Banco</label>
                                                <SelectInput
                                                    label="Banco"
                                                    name="id_banco"
                                                    options={banks.map((item) => ({
                                                        name: item.nombre,
                                                        id: item.id,
                                                    }))}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <label>Tipo de cuenta</label>
                                            <select
                                                className='form-control'
                                                name='tipo_cuenta'
                                                required={true}
                                                onChange={handleChange}
                                            >
                                                <option value={0} hidden>Seleccione un tipo de cuenta</option>
                                                <option value="saving" selected={form.tipo_cuenta === 'saving'}>Ahorro
                                                </option>
                                                <option value="current"
                                                        selected={form.tipo_cuenta === 'current'}>Corriente
                                                </option>
                                            </select>
                                        </div>
                                        <div className="col-md-4">
                                            <Input
                                                label="Número de cuenta"
                                                name="numero_cuenta"
                                                onChange={handleChange}
                                                value={form.numero_cuenta}
                                            />
                                        </div>
                                        <div className="col-md-4 mt-2">
                                            <CheckBox label="Enviar notificación de empleado nuevo"
                                                      name="nuevo_empleado" id="nuevo_empleado" onChange={handleChange}
                                                      state={form.nuevo_empleado}/>
                                        </div>
                                        {(form.nuevo_empleado === true) &&
                                            <>
                                                <div className="col-12 mt-2">
                                                    <div className="d-flex">
                                                        <h5 className="mt-1">Coreos adicionales</h5>
                                                        <Button type="button" onClick={() => {
                                                            add_email()
                                                        }} text="Agregar" theme="btn btn-success ml-2" classes="m-1"/>
                                                    </div>
                                                </div>
                                                {form.correos_adicionales.length > 0 &&
                                                    form.correos_adicionales.map(ca =>
                                                        <div className="col-md-4">
                                                            <Input
                                                                label={ca.label}
                                                                name={ca.label}
                                                                onChange={handleChangeEmail}
                                                                value={ca.value}
                                                            />
                                                        </div>
                                                    )
                                                }

                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to="/empleados" className="btn btn-danger m-1">
                                        Cancelar
                                    </Link>
                                    <Button
                                        text="Crear"
                                        theme="success"
                                        type="submit"
                                        classes="m-1"
                                        onClick={handleSubmit}
                                        disabled={isLoading}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateEmployee;
