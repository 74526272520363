import axios from "axios";
import {
    URLAPI,
    FIELD_OPTION_CREATE_PATH,
    FIELD_OPTION_DELETE_PATH
} from "../config";
import {LOGOUT} from './users'

const init = {
    _create_field_option: {
        data: {},
        status: 0,
        message: {},
    },
    _delete_field_option: {
        data: {},
        status: 0,
        message: {},
    },
}

export const CREATE_FIELD_OPTION = "CREATE_FIELD_OPTION";
export const DELETE_FIELD_OPTION = "DELETE_FIELD_OPTION";


export const fields_options_module = (state = init, action) => {
    switch (action.type) {
        case CREATE_FIELD_OPTION:
            return {
                ...state,
                _create_field_option: action.payload,
            };
        case DELETE_FIELD_OPTION:
            return {
                ...state,
                _delete_field_option: action.payload,
            };
        case LOGOUT:
            return init;
        default:
            return state;
    }
}

export const create_field_option = (token, data) => async (dispatch) => {
    try {

        const response = await axios.post(`${URLAPI}${FIELD_OPTION_CREATE_PATH}`, data, {
            headers: {
                Authorization: `Token ${token}`,
            },
        });
        dispatch({
            type: CREATE_FIELD_OPTION,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: CREATE_FIELD_OPTION,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CREATE_FIELD_OPTION,
                payload: {
                    data: {},
                    status: 500,
                    message: "Error al crear el registro",
                },
            });
        }
    }
}

export const delete_field_option = (token, id) => async (dispatch) => {
    try {
        const response = await axios.delete(
            `${URLAPI}${FIELD_OPTION_DELETE_PATH}${id}/`,
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
        dispatch({
            type: DELETE_FIELD_OPTION,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: DELETE_FIELD_OPTION,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: DELETE_FIELD_OPTION,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};


export const clear_field_option = (type) => async dispatch => {
    dispatch({
        type: type,
        payload: {
            data: {},
            status: 0,
            message: {},
        },
    });
}