import React, {useEffect, useState} from "react";
import DataTable from "../../../components/data-table";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useSelector, useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {ToastContainer} from 'react-toastify';
import {get_all_zones} from "../../../redux/zones";

const EmailZones = (props) => {
    let history = useHistory();
    let dispatch = useDispatch();

    const _zones = useSelector((state) => state.zone_module._get_all_zones);
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const [data, setData] = useState([]);

    useEffect(() => {
        dispatch(get_all_zones(token));
    }, [token, dispatch]);

    useEffect(() => {
        if (_zones && _zones.data && _zones.data.datos) {
            let values = _zones.data.datos.filter(x => x.activo===true);
            setData(values);
        }
    }, [_zones]);

    const [permisos, setPermisos] = useState([]);
    const [actions, setActions] = useState([]);
    // const [modal, setModal] = useState(false);
    // const [id, setId] = useState(null);

    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);

        }
    }, [props]);

    useEffect(() => {
        let act = []
        if (permisos) {
            if (permisos.filter(x => x.codigo === 'change_zones').length > 0) {
                act.push({
                    name: "email",
                    label: "Correos",
                    icon: "bx bx-show",
                    color: "primary",
                    onClick: (item) => {
                        history.push(`/zona-correo/editar/${item.id}`);
                    },
                })
            }
        }
        setActions(act);
    }, [permisos, history]);

    return (
        <div className="app-content content">
            <div className="content-overlay"/>
            <ToastContainer/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Zonas - Correos"
                            items={[{label: "Lista", link: "/zonas-correo"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="table-responsive">
                                    {data ?
                                        <DataTable
                                            dataTable={data}
                                            columns={[
                                                {
                                                    name: "nombre",
                                                    label: "Nombre",
                                                    type: "text",
                                                },
                                                {
                                                    name: "usuario_jefe_zonal",
                                                    label: "Usurio jefe zonal",
                                                    type: "object",
                                                    field_show: "nombres"
                                                },
                                                {
                                                    name: "fecha_creacion",
                                                    label: "Fecha Creacion",
                                                    type: "text",
                                                },
                                            ]}
                                            actions={actions}
                                        />
                                    :
                                        <div className="mt-2 text-center">
                                            <p>No existe datos a mostrar</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmailZones;
