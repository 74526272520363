import React, {useEffect, useState} from "react";
import DataTable from "../../../components/data-table";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useSelector, useDispatch} from "react-redux";
import {
    Link,
    useHistory
} from "react-router-dom";
import {get_all_companies} from "../../../redux/company";

const Companies = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const _get_all_companies = useSelector((state) => state.company_module._get_all_companies);
    const {token} = useSelector((state) => state.users_module.login.data) || null;
    const [data, setData] = useState([]);
    const [permisos, setPermisos] = useState([]);

   useEffect(() => {
        dispatch(get_all_companies(token));
   }, [token, dispatch]);

    useEffect(() => {
        if (_get_all_companies && _get_all_companies.data && _get_all_companies.data.datos) {
            setData(_get_all_companies.data.datos);
        }
    }, [_get_all_companies]);

    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);

        }
    }, [props]);

    const [actions, setActions] = useState([]);

    useEffect(() => {
        let act = []
        if (permisos) {
            if (permisos.filter(x => x.codigo === 'change_company').length > 0) {
                act.push({
                    name: "edit",
                    label: "Edit",
                    icon: "bx bx-edit",
                    color: "primary",
                    onClick: (item) => {
                        history.push({pathname: `/empresas/editar/${item.id}`, state: {data: item}});
                    },
                })
            }
        }
        setActions(act);


    }, [permisos, history]);

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Empresas"
                            items={[{label: "Empresas", link: "/empresas"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                {
                                    permisos.filter(x => x.codigo === 'add_company').length > 0 ? <Link
                                        to="/empresas/crear"
                                        className="btn btn-success"
                                    >
                                        Crear
                                    </Link> : null
                                }

                                <div className="table-responsive">
                                    <DataTable
                                        dataTable={data}
                                        columns={[
                                            {
                                                name: "nombre",
                                                label: "Nombre",
                                                type: "text",
                                            },
                                            {
                                                name: "ruc",
                                                label: "Ruc",
                                                type: "text",
                                            },
                                            {
                                                name: "activo",
                                                label: "Activo",
                                                type: "boolean",
                                            },
                                            {
                                                name: "fecha_creacion",
                                                label: "Fecha Creacion",
                                                type: "text",
                                            },
                                        ]}
                                        actions={actions}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Companies;
