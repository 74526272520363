import axios from "axios";
import {
    URLAPI,
    FIELD_ITEM_PATH,
    FIELD_CREATE_PATH,
    FIELD_UPDATE_PATH,
} from "../config";
import {LOGOUT} from './users'

const init = {
    _create_field: {
        data: {},
        status: 0,
        message: {},
    },
    _update_field: {
        data: {},
        status: 0,
        message: {},
    },
}

export const CREATE_FIELD = "CREATE_FIELD";
export const UPDATE_FIELD = "UPDATE_FIELD";


export const fields_module = (state = init, action) => {
    switch (action.type) {
        case CREATE_FIELD:
            return {
                ...state,
                _create_field: action.payload,
            };
        case UPDATE_FIELD:
            return {
                ...state,
                _update_field: action.payload,
            };
        case LOGOUT:
            return init;
        default:
            return state;
    }
}

export const create_field = (token, data) => async (dispatch) => {
    try {

        const response = await axios.post(`${URLAPI}${FIELD_CREATE_PATH}`, data, {
            headers: {
                Authorization: `Token ${token}`,
            },
        });
        dispatch({
            type: CREATE_FIELD,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: CREATE_FIELD,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CREATE_FIELD,
                payload: {
                    data: {},
                    status: 500,
                    message: "Error al crear el registro",
                },
            });
        }
    }
}


export const update_field = (token, id, data) => async (dispatch) => {
    try {
        const response = await axios.patch(`${URLAPI}${FIELD_UPDATE_PATH}${id}/`, data, {
            headers: {
                Authorization: `Token ${token}`,
            },
        });
        dispatch({
            type: UPDATE_FIELD,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response.data != null) {
            dispatch({
                type: UPDATE_FIELD,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: UPDATE_FIELD,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
}

export const searchItemField = async (token, id) => {
    return await axios.get(`${URLAPI}${FIELD_ITEM_PATH}${id}/`, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
}

export const clear_field = (type) => async dispatch => {
    dispatch({
        type: type,
        payload: {
            data: {},
            status: 0,
            message: {},
        },
    });
}