import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import LoadingSpinner from "../../../components/loading-spinner";
import Modal from "../../../components/modal";
import {
    UPDATE_GROUP,
    clear_group,
    searchItemGroup,
    update_group
} from "../../../redux/groups";
import CheckBox from "../../../components/check-box";
import SelectInput from "../../../components/select";
import {
    clear_field, 
    CREATE_FIELD, 
    create_field, 
    UPDATE_FIELD, 
    update_field
} from "../../../redux/fields";
import {
    clear_field_option, 
    CREATE_FIELD_OPTION, 
    create_field_option, 
    DELETE_FIELD_OPTION, 
    delete_field_option
} from "../../../redux/fields_options";
import {get_all_field_types} from "../../../redux/field_types";

const UpdateGroup = () => {
    let history = useHistory();
    const {id_section, id_group} = useParams();

    const dispatch = useDispatch();
    const {token} = useSelector((state) => state.users_module.login.data) || null;
    const _update_group = useSelector((state) => state.group_module._update_group);
    const _get_all_field_types = useSelector((state) => state.field_types_module._get_all_field_types);
    const _create_field = useSelector((state) => state.fields_module._create_field);
    const _update_field = useSelector((state) => state.fields_module._update_field);
    const _create_field_option = useSelector((state) => state.fields_options_module._create_field_option);
    const _delete_field_option = useSelector((state) => state.fields_options_module._delete_field_option);

    const [isLoading, setIsLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [modalOption, setModalOption] = useState(false);
    const [types, setTypes] = useState([]);
    const [id, setId] = useState(0);
    const [option, setOption] = useState('');

    useEffect(() => {
        dispatch(get_all_field_types(token));
    }, [token, dispatch]);

    useEffect(() => {
        if (_get_all_field_types && _get_all_field_types.data && _get_all_field_types.data.datos) {
            setTypes(_get_all_field_types.data.datos);
        }
    }, [_get_all_field_types]);

    const [form, setForm] = useState({
        posicion: 0,
        multiple: false,
        requerido: false,
        activo: true,
        campos: [],
    });

    const [data, setData] = useState({
        nombre: '',
        id_tipo: 0,
        tipo: '',
        id_grupo: id_group,
        activo: true,
        opciones: []
    });

    useEffect(() => {
        // eslint-disable-next-line
        getItemGroup();
        // eslint-disable-next-line
    }, [token, id_group]);

    async function getItemGroup() {
        setIsLoading(true);
        try {
            const response = await searchItemGroup(token, id_group);
            if (response && response.data && response.data.datos) {
                let v = response.data.datos;
                setForm({
                    posicion: v.posicion,
                    multiple: v.multiple,
                    requerido: v.requerido,
                    activo: v.activo,
                    campos: v.campos,
                })
            }
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
        }
    }

    const handleChange = (e) => {
        if (e.target.name === 'activo' || e.target.name === 'multiple' || e.target.name === 'requerido') {
            setForm({
                ...form,
                [e.target.name]: e.target.checked,
            });
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            });
        }
    };

    useEffect(() => {
        if (_update_group.status === 200) {
            dispatch(clear_group(UPDATE_GROUP));
            setIsLoading(false);
            history.push(`/seccion/detalle/${id_section}`);
        } else {
            if (_update_group.status !== 0) {
                toast.error(_update_group.message);
                dispatch(clear_group(UPDATE_GROUP));
                setIsLoading(false);
            }
        }
    }, [_update_group, dispatch, history, token, id_section]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (form.posicion === 0) {
            toast.error("Debe ingresar la posición de la sección", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        dispatch(update_group(token, id_group, form));
    };

    const handleChangeData = (e) => {
        if (e.target.name === 'id_tipo') {
            setData({
                ...data,
                [e.target.name]: parseInt(e.target.value),
            });
        } else if (e.target.name === 'activo') {
            setData({
                ...data,
                [e.target.name]: e.target.checked,
            });
        }
        else {
            setData({
                ...data,
                [e.target.name]: e.target.value,
            });
        }
    };

    useEffect(() => {
        if (_create_field.status === 201) {
            toast.success("Creado correctamente", {position: toast.POSITION.TOP_RIGHT});
            dispatch(clear_field(CREATE_FIELD));
            getItemGroup();
            setIsLoading(false);
            setModal(false);
        } else if (_create_field.status !== 0) {
            dispatch(clear_field(CREATE_FIELD));
            toast.error(_create_field.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
        // eslint-disable-next-line
    }, [_create_field, dispatch, history, token]);

    useEffect(() => {
        if (_update_field.status === 200) {
            toast.success("Actualizado correctamente", {position: toast.POSITION.TOP_RIGHT});
            dispatch(clear_field(UPDATE_FIELD));
            getItemGroup();
            setIsLoading(false);
            setModal(false);
            // history.push(`/seccion/grupo/${id}/${_create_group.data.datos.id}`);
        } else if (_update_field.status !== 0) {
            dispatch(clear_field(UPDATE_FIELD));
            toast.error(_update_field.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
        // eslint-disable-next-line
    }, [_update_field, dispatch, history, token]);


    const handleSubmitField = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (data.nombre === '') {
            toast.error("Debe ingresar el nombre", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (data.id_tipo === 0) {
            toast.error("Debe seleccionar el tipo", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (id === 0) {
            dispatch(create_field(token, data));
        } else {
            dispatch(update_field(token, id, data));
        }
        
    };

    useEffect(() => {
        if (_create_field_option.status === 201) {
            toast.success("Creado correctamente", {position: toast.POSITION.TOP_RIGHT});
            dispatch(clear_field_option(CREATE_FIELD_OPTION));
            getItemGroup();
            setIsLoading(false);
            setModalOption(false);
        } else if (_create_field_option.status !== 0) {
            dispatch(clear_field_option(CREATE_FIELD_OPTION));
            toast.error(_create_field_option.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
        // eslint-disable-next-line
    }, [_create_field_option, dispatch, history, token]);

    const handleSubmitOption = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (option === '') {
            toast.error("Debe ingresar una opción", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        dispatch(create_field_option(token, {'id_campo': id, 'descripcion': option}));
        
    };

    useEffect(() => {
        if (_delete_field_option.status === 200) {
            dispatch(clear_group(DELETE_FIELD_OPTION));
            setIsLoading(false);
            getItemGroup();
        } else {
            if (_delete_field_option.status !== 0) {
                toast.error(_delete_field_option.message);
                dispatch(clear_group(DELETE_FIELD_OPTION));
                setIsLoading(false);
            }
        }
        // eslint-disable-next-line
    }, [_delete_field_option, dispatch, history, token]);

    function delete_option(id_option) {
        setIsLoading(true);
        dispatch(delete_field_option(token, id_option));
    }

    return (
        <div className="app-content content">
            <div className="content-overlay"/>
            <div><ToastContainer/></div>
            {isLoading && <LoadingSpinner/>}
            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Formulario de empleados"
                            items={[{label: "Confguración de datos", link: "/secciones"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Actualizar Grupo</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-4 col-12">
                                            <Input
                                                label="Posición"
                                                name="posicion"
                                                type="number"
                                                value={form.posicion}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 col-12">
                                            <CheckBox label="Es múltiple" name="multiple" id="multiple"
                                                      onChange={handleChange}
                                                      state={form.multiple}/>
                                        </div>
                                        <div className="col-md-4 col-12">
                                            <CheckBox label="Es requerido" name="requerido" id="requerido"
                                                      onChange={handleChange}
                                                      state={form.requerido}/>
                                        </div>
                                        <div className="col-md-4 col-12">
                                            <CheckBox label="Activo" name="activo" id="activo" onChange={handleChange}
                                                      state={form.activo}/>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-6 col-12">
                                            <h5 className="text-title">Campos agregados</h5>
                                        </div>
                                        <div className="col-md-6 col-12 text-right">
                                            <Button type="button" text="Agregar campo" theme="secondary" classes="mb-1"
                                                    onChange={() => {
                                                        setData({
                                                            nombre: '',
                                                            tipo: '',
                                                            id_tipo: 0,
                                                            id_grupo: id_group,
                                                            opciones: []
                                                        })
                                                        document.getElementById("id_tipo").selectedIndex = 0;
                                                        setId(0);
                                                        setModal(true);
                                                    }}/>
                                        </div>
                                        {(form.campos && form.campos.length > 0) &&
                                            form.campos.map((g) =>
                                                <div className="col-12" style={{padding: "0 2em"}}>
                                                    <div className="border_card">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <span>Nombre</span>
                                                                <p className="border_card">{g.nombre}</p>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <span>Tipo</span>
                                                                <p className="border_card">{g.tipo}</p>
                                                            </div>
                                                            <div className="col-md-4 mt-2">
                                                                <strong>Activo: </strong>
                                                                {g.activo ?
                                                                    <i className="bx bx-check-square"/>
                                                                    :
                                                                    <i className="bx bx-checkbox"/>
                                                                }
                                                            </div>
                                                            {g.opciones.length > 0 &&
                                                                g.opciones.map(op => (
                                                                    <div className="col-md-4 col-12"> 
                                                                        <div className="d-flex">
                                                                            <div style={{width: '90%'}}>
                                                                                <label>Opción</label>
                                                                                <p className="border_card">{op.descripcion}</p>
                                                                            </div>
                                                                            <div style={{width: '10%', paddingTop: 20}} className="text-right">
                                                                                <img
                                                                                    src={window.location.origin + "/base/app-assets/images/icon/delete.png"}
                                                                                    alt="img"
                                                                                    height="30"
                                                                                    width="30"
                                                                                    onClick={() => {
                                                                                        delete_option(op.id)
                                                                                    }}
                                                                                    style={{cursor: "pointer"}}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-4 col-12">
                                                                <Button type="button" text="Editar campo" theme="secondary"
                                                                    classes="mb-1"
                                                                    onChange={() => {
                                                                        setId(g.id);
                                                                        setData({
                                                                            nombre: g.nombre,
                                                                            tipo: g.tipo,
                                                                            id_tipo: g.id_tipo,
                                                                            activo: g.activo,
                                                                            opciones: g.opciones
                                                                        });
                                                                        setModal(true);
                                                                    }}
                                                                />
                                                            </div>
                                                            {g.tipo === 'Selección' &&
                                                                <div className="col-md-4 col-12">
                                                                    <Button type="button" text="Agregar opción" theme="primary"
                                                                        classes="mb-1"
                                                                        onChange={() => {
                                                                            setId(g.id);
                                                                            setOption('');
                                                                            setModalOption(true);
                                                                        }}
                                                                    />
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>

                                    <div className="card-footer d-flex justify-content-end">
                                        <Link to={`/seccion/detalle/${id_section}`} className="btn btn-danger m-1">
                                            Cancelar
                                        </Link>
                                        <Button
                                            type="submit"
                                            text={
                                                <>
                                                    <i className="bx bx-save"/> Guardar
                                                </>
                                            }
                                            theme="success"
                                            disabled={isLoading}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal-main div-modal" show={modal}>
                <form onSubmit={handleSubmitField}>
                    {isLoading && <LoadingSpinner/>}
                    <div className="card-body">
                        <h5 className="titulo_modal type_text mb-2">{id === 0 ? 'Crear' : 'Editar'} campo</h5>
                        <div className="form-group">
                            <Input
                                label="Nombre"
                                name="nombre"
                                value={data.nombre}
                                onChange={handleChangeData}
                            />
                        </div>
                        <div className="form-group">
                            <label>Tipo</label>
                            <SelectInput
                                label="Tipo"
                                id="id_tipo"
                                name="id_tipo"
                                options={types.map((item) => ({
                                    name: item.nombre,
                                    id: item.id,
                                }))}
                                select={data.tipo}
                                onChange={handleChangeData}
                            />
                        </div>
                        {(id !== 0) &&
                            <div className="form-group">
                                <CheckBox label="Activo" name="activo" id="activo" onChange={handleChangeData}
                                            state={data.activo}/>
                            </div>
                        }
                        {(id !== 0 && data.opciones && data.opciones.length > 0) &&
                            <div className="form-group">
                                <h5>Opciones</h5>
                                {data.opciones.map(x => (
                                    <div>
                                        <label>Opción</label>
                                        <p className="border_card">{x.descripcion}</p>
                                    </div>
                                ))}
                                
                            </div>
                        }
                    </div>
                    <div className="text-center">
                        <button type="button" className="btn btn-outline-dark" onClick={() => {
                            setModal(false);
                        }}> Cancelar
                        </button>
                        <Button type="submit" text="Guardar" theme="success" classes="m-1"/>
                    </div>
                </form>
            </Modal>
            <Modal className="modal-main div-modal" show={modalOption}>
                <form onSubmit={handleSubmitOption}>
                    {isLoading && <LoadingSpinner/>}
                    <div className="card-body">
                        <h5 className="titulo_modal type_text mb-2">Agregar opción</h5>
                        <div className="form-group">
                            <Input
                                label="opcion"
                                name="opcion"
                                value={option}
                                onChange={(e) => setOption(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="text-center">
                        <button type="button" className="btn btn-outline-dark" onClick={() => {
                            setModalOption(false);
                        }}> Cancelar
                        </button>
                        <Button type="submit" text="Guardar" theme="success" classes="m-1"/>
                    </div>
                </form>
            </Modal>
        </div>
    );
};

export default UpdateGroup;
